import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

//components made using mui-core
import Modals from "components/Modal/Modals";
import Primary from "components/Typography/Primary";
import Info from "components/Typography/Info";
import Table from "../../../components/Table/Table";
import PlayListTable from "components/Table/PlayListTable";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
//mui core component
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  Button,
  Paper,
  Switch,
  OutlinedInput,
} from "@material-ui/core";

//Style File
import styles from "../../../assets/jss/material-dashboard-react/views/widgetMangerStyle";
//Actions
import {
  getCategoryPlayListByIdAction,
  searchPlaylistAction,
  getCategoryPlayListResponse,
} from "../actions";
const useStyles = makeStyles(styles);

const PlayList = (props) => {
  const [categorySelected, setCategorySelected] = React.useState(0);
  const [subCategorySelected, setSubCategorySelected] = React.useState(-1);
  const [lastCategorySelected, setLastCategorySelected] = React.useState(-1);

  const [subFolder, setSubFolder] = React.useState(false);
  const [superSubFolder, setSuperSubFolder] = React.useState(false);
  const [subFolderName, setSubFolderName] = React.useState();
  const [folder, setFolder] = React.useState();

  const classes = useStyles();
  React.useEffect(() => {
    for (var i = 0; i < props?.categories?.length; i++) {
      if (props.categories[i].childrenCount > 0) {
        setCategorySelected(i);
        break;
      }
    }

    const id = props?.categories[categorySelected]?.id;

    props.open
      ? props.getCategoryPlayListByIdAction(id, props?.projectId)
      : null;
  }, [props.open]);

  //Onclick actions
  const activeCategory = (list, index) => {
    setCategorySelected(index);
    props.getCategoryPlayListByIdAction(list.id, props?.projectId);
  };

  const activeSubCategory = (list, index) => {
    setSubCategorySelected(index);
    props.getCategoryPlayListByIdAction(list.id, props?.projectId);
  };
  const activeLastCategory = (list, index) => {
    setLastCategorySelected(index);
    props.getCategoryPlayListByIdAction(list.id, props?.projectId);
  };

  const openSubFolder = (list) => {
    setFolder(list.name);

    setSubFolder(!subFolder);
  };

  const openSuperSubFolder = (list) => {
    setSubFolderName(list.name);

    setSuperSubFolder(!superSubFolder);
  };

  //OnChnage Event for the search playlist
  const searchPlayList = (event) => {
    const searchValue = event.target.value;

    const searchArray = props.categoryList?.playList?.filter((playlist) => {
      return playlist.name
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    searchValue
      ? props.searchPlaylistAction(searchArray)
      : props.getCategoryPlayListResponse(props?.categoryList?.playListFixed);
    //props.getCategoryPlayListResponse(searchArray)
  };

  //small components

  /* const FolderStructure = ({
    list,
    subFolder,
    openSubFolder,
    activeCategory,
  }) => {
  
    return (
      <List className={classes.listOfCategory}>
        {list.map((listChild, index) => (
          <FolderItem
            list={listChild}
            subFolder={subFolder}
            openSubFolder={openSubFolder}
            activeCategory={activeCategory}
            index={index}
          />
        ))}
      </List>
    );
  };

  const FolderItem = ({ list, subFolder,
    openSubFolder,
    activeCategory,index }) => {
    const [childVisible, setChildVisiblity] = React.useState(false);

    const hasChild = list.children === "KalturaCategoryTree" ? true : false;

    return (
      <>
        <ListItem
          onClick={() => {
            activeCategory(list, index);
          }}
        >
          <ListItemText className={classes.playlistText} primary={list.name} />
          {subFolder ? (
            <ExpandLessIcon onClick={() => openSubFolder(list)} />
          ) : (
            <ExpandMoreIcon onClick={() => openSubFolder(list)} />
          )}
        </ListItem>
        {hasChild && childVisible ? (
          <FolderStructure list={list.children} />
        ) : null}
      </>
    );
  };
 */
  //main component
  return (
    <Modals
      open={props.open}
      setModal={props.setOpenModal}
      setAssetIdBrowse={props.setAssetIdBrowse}
    >
      {props.loading ?props.children:<>
     <div className={classes.modalDiv}>
        <div className={classes.modalHeadingDiv}>
          <Primary>Browse PlayList({props.totalPlayList})</Primary>
        </div>
        <div style={{ display: "flex" }}>
          <div className={classes.playListDiv}>
            <Info>Folder Name</Info>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            {/*  <FolderStructure
              list={props.categories}
              subFolder={subFolder}
              openSubFolder={openSubFolder}
              activeCategory={activeCategory}
            /> */}
            <List className={classes.listOfCategory}>
              {props?.categories?.map((list, index) => {
                var selctedListClass;
                const categoriesShow = `${list.name}(${list.childrenCount})`;
                if (index === categorySelected) {
                  selctedListClass = classNames({
                    ["  " + classes.selectedCategory]: true,
                  });
                }
                return list.childrenCount > 0 ? (
                  <>
                    {" "}
                    <ListItem
                      className={classNames(
                        classes.PlaylistListItem,
                        selctedListClass
                      )}
                      onClick={() => {
                        activeCategory(list, index);
                      }}
                      key={index}
                    >
                      <ListItemText
                        className={classes.playlistText}
                        primary={categoriesShow}
                      />
                      {subFolder && list.name === folder ? (
                        <ExpandLessIcon onClick={() => openSubFolder(list)} />
                      ) : (
                        <ExpandMoreIcon onClick={() => openSubFolder(list)} />
                      )}
                    </ListItem>
                    {subFolder && list.name === folder
                      ? list.children.map((child, index) => {
                          var selctedSubListClass;
                          const subCategoriesShow = `${child.name}(${child.childrenCount})`;
                          if (index === subCategorySelected) {
                            selctedSubListClass = classNames({
                              ["  " + classes.selectedSubCategory]: true,
                            });
                          }
                          return child.objectType === "KalturaCategoryTree" ? (
                            <>
                              <ListItem
                                className={classNames(
                                  classes.PlaylistListSubItem,
                                  selctedSubListClass
                                )}
                                style={{ marginLeft: "10px" }}
                                onClick={() => {
                                  activeSubCategory(child, index);
                                }}
                                key={index}
                              >
                                <ListItemText
                                  className={classes.playSublistText}
                                  primary={subCategoriesShow}
                                />
                                {superSubFolder &&
                                child.name === subFolderName ? (
                                  <ExpandLessIcon
                                    onClick={() => openSuperSubFolder(child)}
                                  />
                                ) : (
                                  <ExpandMoreIcon
                                    onClick={() => openSuperSubFolder(child)}
                                  />
                                )}
                              </ListItem>

                              {superSubFolder && subFolderName === child.name
                                ? child.children?.map((lastChild, index) => {
                                    var selctedLastListClass;
                                    const lastCategoriesShow = `${lastChild.name}(${lastChild.childrenCount})`;
                                    if (index === lastCategorySelected) {
                                      selctedLastListClass = classNames({
                                        ["  " +
                                        classes.selectedLastCategory]: true,
                                      });
                                    }
                                    return lastChild.objectType ===
                                      "KalturaCategoryTree" ? (
                                      <ListItem
                                        className={classNames(
                                          classes.PlaylistListLastItem,
                                          selctedLastListClass
                                        )}
                                        style={{ marginLeft: "20px" }}
                                        onClick={() => {
                                          activeLastCategory(lastChild, index);
                                        }}
                                        key={index}
                                      >
                                        <ListItemText
                                          className={classes.playSublistText}
                                          primary={lastCategoriesShow}
                                        />
                                      </ListItem>
                                    ) : null;
                                  })
                                : null}
                            </>
                          ) : null;
                        })
                      : null}
                  </>
                ) : null;
              })}
            </List>
          </div>
          <div className={classes.playlists}>
            <div className={classes.playlistUpperDiv}>
              <TextField
                placeholder="Enter Widget Name"
                variant="outlined"
                size="Normal"
                onChange={searchPlayList}
              />
            </div>
            <div className={classes.playlistView}>
              {props?.categoryList?.loadingPlayList ? (
                <div>Loading...</div>
              ) : props?.categoryList?.error ? (
                <div>{`${props?.categoryList?.errorMessage},Please Check Sub Folder`}</div>
              ) : (
                <PlayListTable
                  tableHead={{
                    name: "PlayList Name",
                    id: "PlayList Id",
                    image: "Image",
                  }}
                  tableData={props?.categoryList?.playList}
                  imageRatio={props.image}
                  imageHeight={props.imageHeight}
                  imageWidth={props.imageWidth}
                  numOfImage={props.numOfImage}
                  closeModal={props.setOpenModal}
                  playListId={props.playListId}
                  setPlayListId={props.setPlayListId}
                  widgetCommonDetails={props.widgetCommonDetails}
                  setWidgetCommonDetails={props.setWidgetCommonDetails}
                  getAssetIdBrowse={props.getAssetIdBrowse}
                  setAssetIdBrowse={props.setAssetIdBrowse}
                  heroAssetId={props.heroAssetId}
                  setHeroAssetId={props.setHeroAssetId}
                  heroWidgetData={props.heroWidgetData}
                  setHeroWidgetDataType={props.setHeroWidgetDataType}
                ></PlayListTable>
              )}
            </div>
          </div>
        </div>
      </div>
      </>}
    </Modals>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryList: state.PlayListReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryPlayListByIdAction: (id, projectId) =>
      dispatch(getCategoryPlayListByIdAction(id, projectId)),
    searchPlaylistAction: (searchText) => {
      dispatch(searchPlaylistAction(searchText));
    },
    getCategoryPlayListResponse: (searchedPlaylist) => {
      dispatch(getCategoryPlayListResponse(searchedPlaylist));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayList);
