import React, { useState, useEffect, useLayoutEffect } from "react";

import {
  Box,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  ListItem,
  Chip,
  Radio,
  RadioGroup, FormControlLabel
} from "@material-ui/core";
import { SettingsApplications } from "@material-ui/icons";
// import ColorPicker from "material-ui-color-picker";
import { connect } from "react-redux";

import AddSign from "assets/img/addWidgetManager/AddSign.svg";
import CloseIcon from 'assets/img/icons/svg/CloseIcon.svg';
import Style from "../../../assets/jss/material-dashboard-react/views/addProjectStepperStyle";
import { getAllLanguage } from "../actions/projectActions";
import rgbaToHex from "utils/rgbaToHex";
import { SketchPicker } from "react-color";
import ColorPickerComponent from "components/ColorPicker";
import axios from "axios";
import StopIcon from '@mui/icons-material/Stop';
import NavigationIcon from '@mui/icons-material/Navigation';
const useStyle = makeStyles(Style);



const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  variant: "menu",
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
var options = [];
const ProjectSettings = (props) => {
  const classes = useStyle();
  const [Language, setLanguage] = useState([]);
  const [metaTags, setMetaTags] = useState([]);
  const [ratios, setRatios] = useState([]);
  const [newText, setNewText] = useState("");
  const [newRatios, setNewRatios] = useState("");
  const [pRatio, setPRatio] = useState("0px");
  const [pTag, setPTag] = useState("0px");
  const [selectedIcon, setSelectedIcon] = useState('forward');

  const [displayColorPicker, setDisplayColorPicker] = useState({
    bgColor: false,
    fgColor: false,
    hgColor: false,
    gdColor: false,
    ftColor: false,
    mnColor: false,
    btn_gdColor: false
  });

  const [imageInputError, setImageInputError] = useState({
    "16x9":"",
    "1x1":"",
    "ContentBadge-Image":""
  });

  useEffect(() => {
    props.getAllLanguage();
  }, []);

  useEffect(() => {
    options = Object.keys(props.allLocales);
  }, []);

  useEffect(() => {
    let enabledlang = [];
    console.log("configDetails:", props.configDetails);
    for (let item of Language) {
      if (props?.allLocales[item]) {
        enabledlang.push({ [item]: props?.allLocales[item] });
      }
    }
    enabledlang?.length
      ? props.setConfigDetails({
          ...props.configDetails,
          enabledLanguages: enabledlang,
        })
      : null;
  }, [Language]);

  const handleBackgroundOption = (event) => {
    switch(event.target.value){
        case "bgColor":
          props.setConfigDetails({ ...props.configDetails, bgOption: event.target.value,gdColor:"",    bgImage: {
            ["1x1"]: "",
            ["16x9"]: "",
          }, });
       
          break
        case "gdColor":
          props.setConfigDetails({ ...props.configDetails, bgOption: event.target.value,bgColor:"", bgImage: {
            ["1x1"]: "",
            ["16x9"]: "",
          },});

          break
        case "bgImage":
          props.setConfigDetails({ ...props.configDetails, bgOption: event.target.value,bgColor:"",gdColor:"" });
          break
    }
  } ;

  const handleBGColor = (value) => {
    const hexValue = rgbaToHex(
      value.rgb.r,
      value.rgb.g,
      value.rgb.b,
      value.rgb.a,
    );
    props.setConfigDetails({ ...props.configDetails, bgColor: hexValue });
  };

  const handleFGColor = (value) => {
    const hexValue = rgbaToHex(
      value.rgb.r,
      value.rgb.g,
      value.rgb.b,
      value.rgb.a,
    );
    props.setConfigDetails({ ...props.configDetails, fgColor: hexValue });
  };

  const handleHGColor = (value) => {
    const hexValue = rgbaToHex(
      value.rgb.r,
      value.rgb.g,
      value.rgb.b,
      value.rgb.a,
    );
    props.setConfigDetails({ ...props.configDetails, hgColor: hexValue });
  };

  const handleGDColor = (value) => {
    props.setConfigDetails({ ...props.configDetails, gdColor: value });
  };

  const handleMNColor = (value) => {
    const hexValue = rgbaToHex(
      value.rgb.r,
      value.rgb.g,
      value.rgb.b,
      value.rgb.a,
    );
    props.setConfigDetails({ ...props.configDetails, mnColor: hexValue });
  };

  const handleFTColor = (value) => {
    const hexValue = rgbaToHex(
      value.rgb.r,
      value.rgb.g,
      value.rgb.b,
      value.rgb.a,
    );
    props.setConfigDetails({ ...props.configDetails, ftColor: hexValue });
  };

  const handleBTN_GDColor = (value) => {
    props.setConfigDetails({ ...props.configDetails, btn_gdColor: value });
  };

  const handleBGPicker = () => {
    setDisplayColorPicker((prev) => {
      return { ...prev, bgColor: !prev.bgColor };
    });
  };

  const handleFGPicker = () => {
    setDisplayColorPicker((prev) => {
      return { ...prev, fgColor: !prev.fgColor };
    });
  };

  const handleHGPicker = () => {
    setDisplayColorPicker((prev) => {
      return { ...prev, hgColor: !prev.hgColor };
    });
  };

  const handleGDPicker = () => {
    setDisplayColorPicker((prev) => {
      return { ...prev, gdColor: !prev.gdColor };
    });
  };

  const handleMNPicker = () => {
    setDisplayColorPicker((prev) => {
      return { ...prev, mnColor: !prev.mnColor };
    });
  };

  const handleFTPicker = () => {
    setDisplayColorPicker((prev) => {
      return { ...prev, ftColor: !prev.ftColor };
    });
  };

  const handleBTN_GDPicker = () => {
    setDisplayColorPicker((prev) => {
      return { ...prev, btn_gdColor: !prev.btn_gdColor };
    });
  };

  const handleLanguage = (event) => {
    const {
      target: { value },
    } = event;
    setLanguage(typeof value === "string" ? value.split(",") : value);
  };

  const handleFilter = (event) => {
    const id = event.target.id;
    const value = event.target.value;
    id === "Aspect Ratio"
      ? setNewRatios(value)
      : id === "Tags"
      ? setNewText(value)
      : null;
  };

  const handleKeypress = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    if (e.key === "Enter") {
      if (id === "Aspect Ratio") {
        ratios.includes(value)
          ? null
          : value
          ? setRatios((filter) => [...filter, value])
          : null;
        setNewRatios("");
      }
      if (id === "Tags") {
        metaTags.includes(value)
          ? null
          : value
          ? setMetaTags((filter) => [...filter, value])
          : null;
        setNewText("");
      }
    }
  };
  const handleImageInputError=(name,message)=>{
    props.setConfigDetails({
      ...props.configDetails,
      bgImage:{
        ...(props?.configDetails?.bgImage ? props?.configDetails?.bgImage : {}),
        [name]:"",
      }
    })
    setImageInputError(error=>({
      ...error,[name]:message
    }))
}


const handleChange = (event) => {
  setSelectedIcon(event.target.value);
};

  const getRatio = async (url) => {
    console.log("URL rat", url);
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        let ratio = img.width / img.height;
        resolve(ratio);
      };
    });
  };

  const getBase64 = async (files) => {
    if (files.length === 1) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = async (e) => {
          const ratio = await getRatio(e.target.result);
          resolve([{ b64: reader.result, ratio }]);
        };
      });
    } else {
      const fileData = Object.values(files);
      const filePathsPromises = [];
      fileData.forEach((file) => {
        filePathsPromises.push(
          new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (e) => {
              const ratio = await getRatio(e.target.result);
              resolve({ b64: reader.result, ratio });
            };
          })
        );
      });
      const filePaths = await Promise.all(filePathsPromises);
      return filePaths;
    }
  };
  const imageHandler = async (event,index=null) => {
    const files = event.target.files;

    const imageSizes = [...Array(files.length)].map((_, index) => files[index].size);

    const fileType = [...Array(files.length)].map((_, index) => {
      return files[index].type;
    });
    let _supportedImageTypes;
    const _backgroundImageSupportedImageTypes = ["image/png", "image/jpeg"];
    const _loaderImageSupportedImageTypes = ["image/png", "image/jpeg"];

    const _contentbadgeSupportedImageTypes = ["image/png", "image/gif"];
    if(event.target.id=="16x9" || event.target.id=="1x1"){
        _supportedImageTypes = _backgroundImageSupportedImageTypes
    }
   else if(event.target.id=="1x1loaderImage"){
      _supportedImageTypes = _loaderImageSupportedImageTypes
  }
    else if (event.target.id=="ContentBadge-Image"){
      _supportedImageTypes = _contentbadgeSupportedImageTypes
    }
    if (
      fileType.every((type) => {
        console.log(type,"type")
        return !_supportedImageTypes.includes(type);
      })
    ) {
      return files.length
        ? handleImageInputError(event.target.id, `Only ${_supportedImageTypes.map(type=>type.slice(5,type.length)).join(",")} files are supported.`,)
        : null;
        
    }
  
    if (imageSizes.every((size) => size < 1048576*5)) {
      const images = await getBase64(files);
      console.log("images", images, images.length);

      const uploadAPI = async (images) => {
        let _endpoint = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-file`
        if (event.target.id.toLowerCase().includes("contentbadge")){
            _endpoint  = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-content-badge`
        }
        return await axios.post(
          _endpoint,
          { file: images[0].b64 },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_X_API_KEY,
              Authorization: localStorage.getItem("token"),
            },
          }
        ).then((res) => {
          return res;
        })
        .catch((err) => {
          console.log("Error:", err);
        });
      };


      const checkAR = (images, platform) => {
        console.log(images[0].ratio,"ratio")
        if (images.length > 1) {
          if (platform === "wide") {
            return images.every((img) => img.ratio > 1 && img.ratio < 2) ? true : false;
          } else if (platform === "small") {
            return images.every((img) => img.ratio <= 1) ? true : false;
          }
          else if (platform === "1x1") {
            return images.every((img) => img.ratio === 1) ? true : false;
          }
        } else if (images.length === 1) {
          if (platform === "wide") {
            return images[0].ratio > 1 && images[0].ratio < 2 ? true : false;
          } else if (platform === "small") {
            return images[0].ratio <= 1 ? true : false;
          }
          else if (platform === "1x1") {
            return images[0].ratio === 1 ? true : false;
          }
        }
      };

      if (images.length && event.target.id) {
        let S3image;
        console.log(event.target.id,"event.target.id")
        if (event.target.id ==="16x9"){
          if(!checkAR(images,"wide")){
            return handleImageInputError(event.target.id,"This platform supports 16x9 image ratio only.")
          }
        }else if (event.target.id ==="1x1" ){

          if(!checkAR(images,"small")){
            
            return handleImageInputError(event.target.id,"This platform supports  9x16 image ratio only.")
          }
        }
        else if (event.target.id ==="1x1loaderImage"){
          if(!checkAR(images,"1x1")){
            
            return handleImageInputError(event.target.id,"This platform supports  1x1 image ratio only.")
          }
        }
        S3image = await uploadAPI(images)
        if(S3image?.data?.uploadResult?.Location){
          handleImageInputError(event.target.id,"")
          if(event.target.id === "1x1" || event.target.id==="16x9"){
          props.setConfigDetails({
            ...props.configDetails,
            bgImage:{
              ...(props?.configDetails?.bgImage ? props?.configDetails?.bgImage : {}),
              [event.target.id]:S3image?.data?.uploadResult?.Location,
            }
          })
        }

        if(event.target.id === "1x1loaderImage"){
          props.setConfigDetails({
            ...props.configDetails,
            loaderImage:{
              ...(props?.configDetails?.loaderImage ? props?.configDetails?.loaderImage : {}),
              ["1x1"]:S3image?.data?.uploadResult?.Location,
            }
          })
        }
        else if (event.target.id === "ContentBadge-Image"){
          handleContentBadgeImageurl(S3image?.data?.uploadResult?.Location)
        }
        else if (event.target.id === "ContentBadge-Image-reupload"){
          updateContentBadgeImageurl(S3image?.data?.uploadResult?.Location,index)
        }
        }else{
          handleImageInputError(event.target.id,"Image could not be uploaded. Please try again.");
        }
       
      } else {
        handleImageInputError(event.target.id,"Image could not be uploaded. Please try again.");
      }
    } else {
      handleImageInputError(event.target.id,"Maximum file limit is 150MB.")
    }
  };

  const handleFont = (event) => {
    props.setConfigDetails({
      ...props.configDetails,
      font: event.target.value,
    });
  };

  //content badge

  const [contentBadge,setContentBadge]   = useState(null)

  const handleAddContentBadge  = ()=>{
    // add content badge 
    if(contentBadge?.["key"] && contentBadge?.["position"] && contentBadge?.["imageUrl"]){
        props.setConfigDetails({
          ...props.configDetails,
          contentBadges: (props?.configDetails?.contentBadges?.length?[...props?.configDetails?.contentBadges,contentBadge]:[contentBadge]) 
        }
        )
        setContentBadge({})
      }else{
        setContentBadge({
          ...contentBadge,
          error:"**Required"
        })
  }
}
  const handleDeleteContentBadge  = (index)=>{
    // delete content badge 
    if( props.configDetails?.contentBadges?.length>0){
      const updatedContentBadges =  props.configDetails.contentBadges.filter((_,i)=>{
       return  i!==index
      })
      props.setConfigDetails({
        ...props.configDetails,
        contentBadges: updatedContentBadges
      });
    }
  }

  const handleContentBadgeType = (e) => {
   
   
    setContentBadge(data=>({...data,key:e.target.value}))
  }
  const updateContentBadgeType = (e,index) => {
   
    const _updatedContentBadges = props?.configDetails?.contentBadges?.map((badge,i)=>{
      if (i===index){
        return {
          ...badge,key:e.target.value
        }
      }
      return badge
    }) || []
    props.setConfigDetails({
      ...props.projectData,
      contentBadges: _updatedContentBadges
    });
   
  }
  const handleContentBadgePosition = (e) => {

    
    
    setContentBadge(data=>({...data,position:e.target.value}))
  }
  const updateContentBadgePosition = (e,index) => {
    const _updatedContentBadges = props?.configDetails?.contentBadges?.map((badge,i)=>{
      if (i===index){
        return {
          ...badge,position:e.target.value
        }
      }
      return badge
    }) || []

    props.setConfigDetails({
      ...props.projectData,
      contentBadges: _updatedContentBadges
    });
  
  }

  const handleContentBadgeImageurl = (url) => {
    setContentBadge(data=>({...data,imageUrl:url}))         
  }
  
  const updateContentBadgeImageurl = (url) => {
    const _updatedContentBadges = props?.configDetails?.contentBadges?.map((badge,i)=>{
      if (i===index){
        return {
          ...badge,imageUrl:url
        }
      }
      return badge
    }) || []
    props.setConfigDetails({
      ...props.projectData,
      contentBadges: _updatedContentBadges
    });  
  }

  useEffect(() => {
    ratios.length
      ? props.setAspectRatios((prev) => ({ ...prev, aspectRatios: ratios }))
      : null;
  }, [ratios]);

  useEffect(() => {
    metaTags.length
      ? props.setConfigDetails({ ...props.configDetails, tags: metaTags })
      : null;
  }, [metaTags]);

  const handleDelete = (value) => {
    props?.configDetails?.tags?.length
      ? [
          props.setConfigDetails((prev) => ({
            ...prev,
            tags: prev.tags.filter((item) => item !== value),
          })),
          setMetaTags(metaTags.filter((item) => item !== value)),
        ]
      : setMetaTags(metaTags.filter((item) => item !== value));
  };

  const handleDeleteRatio = (value) => {
    props?.aspectRatios?.aspectRatios?.length
      ? [
          props.setAspectRatios(
            props.aspectRatios.aspectRatios.filter((item) => item !== value),
          ),
          setRatios(ratios.filter((item) => item !== value)),
        ]
      : setRatios(ratios.filter((item) => item !== value));
  };

  useLayoutEffect(() => {
    props?.aspectRatios?.aspectRatios?.length
      ? setPRatio("20px")
      : ratios.length
      ? setPRatio("20px")
      : setPRatio("0px");
  }, [ratios, props?.aspectRatios?.aspectRatios]);

  useLayoutEffect(() => {
    metaTags.length ? setPTag("20px") : setPTag("0px");
  }, [metaTags]);


  return (
    <Box paddingLeft="24px">
      <Grid>
        <Grid item sm={12} md={12} lg={12}>
          <Box>
            <Box pb={3} paddingRight="33px">
              <Box gridGap={"50px"} component="form" role="form">
                <Box paddingBottom="20px">
                  <Typography
                    id="Select Aspect Ratios"
                    className={classes.inputLabel}
                  >
                    Aspect Ratios
                  </Typography>
                  <TextField
                    onChange={handleFilter}
                    fullWidth
                    style={{ maxWidth: "50%" }}
                    className={classes.textfield}
                    variant="outlined"
                    size="small"
                    onKeyUp={handleKeypress}
                    value={newRatios ?? ""}
                    id="Aspect Ratio"
                  />
                  <FormHelperText  style={{ color:"red"}}>
                    {!props?.aspectRatios?.aspectRatios?.length &&
                      props?.ErrorMessage}
                  </FormHelperText>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    paddingBottom: pRatio,
                  }}
                >
                  {props?.aspectRatios?.aspectRatios?.length ? (
                    <>
                      {props?.aspectRatios?.aspectRatios.map((x, index) => {
                        return (
                          <Chip
                            label={x}
                            key={index}
                            onDelete={() => handleDeleteRatio(x)}
                            color="primary"
                          ></Chip>
                        );
                      })}
                    </>
                  ) : ratios.length > 0 ? (
                    <>
                      {ratios.map((x, index) => {
                        return (
                          <Chip
                            label={x}
                            key={index}
                            onDelete={() => handleDeleteRatio(x)}
                            color="primary"
                          />
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}
                </Box>
                <Box paddingBottom="20px">
                <Typography className={classes.inputLabel}>
                  Background Option
                </Typography>
                <TextField
                  id="backgroundOption"
                  value={props.configDetails.bgOption}
                  onChange={handleBackgroundOption}
                  select
                  variant="outlined"
                  style={{minWidth: "200px", backgroundColor: "#FFF", display: "flex", width:"50%"}}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }
                  }}
                >
                  <MenuItem value={"bgColor"}>
                     Color
                  </MenuItem>
                  <MenuItem value={"bgImage"}>
                       Image
                  </MenuItem>
                  <MenuItem value={"gdColor"}>
                      Gradient
                  </MenuItem>
                  </TextField>
                  <FormHelperText  style={{ color:"red"}}>
                        { (props.configDetails?.bgOption === undefined ||
                        props.configDetails?.bgOption === "")
                          ? props.ErrorMessage
                          : ""}
                      </FormHelperText>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    gap: "50px",
                    flexWrap: "wrap",
                    paddingBottom: pTag,
                  }}
                >
                  <Box>
                 
                  <Box>
                <Box paddingBottom="6px">
                    <Typography className={classes.inputLabel}>
                      Button Gradient Color
                    </Typography>
                    <Box
                      className={classes.swatch}
                      style={{
                        background: props.configDetails?.btn_gdColor,
                      }}
                      onClick={handleBTN_GDPicker
                      }
                    >
                      <Box className={classes.color} />
                    </Box>
                    {displayColorPicker.btn_gdColor ? (
                      <Box className={classes.popover}>
                        <Box
                          className={classes.cover}
                          onClick={handleBTN_GDPicker}
                        />
                        <ColorPickerComponent
                          key="gdColorPicker"
                          color={props.configDetails?.btn_gdColor}
                          onChange={handleBTN_GDColor}
                        />
                      </Box>
                    ) : null}
                  </Box>
                </Box>
                <Box>
                <Box paddingBottom="6px">
                    <Typography className={classes.inputLabel}>
                        Menu Color
                    </Typography>
                    <Box
                      className={classes.swatch}
                     
                      style={{
                        background: props.configDetails.mnColor,
                                            
                      }}
                      onClick={handleMNPicker}
     
                    >
                      <Box
                        className={classes.color}
                        style={{
                          background:
                          props.configDetails.mnColor,
                        }}
                      />
                    </Box>
                    {displayColorPicker.mnColor ? (
                      <Box className={classes.popover}>
                        <Box
                          className={classes.cover}
                          onClick={handleMNPicker}
                        />
                        <SketchPicker
                          key="mnColorPicker"
                          color={props.configDetails.mnColor}
                          onChange={handleMNColor}
                        />
                      </Box>
                    ) : null}
                  </Box>
                  <Box paddingBottom="6px">
                    <Typography className={classes.inputLabel}>
                        Font Color
                    </Typography>
                    <Box
                      className={classes.swatch}
                       style={{ background: props.configDetails.ftColor }}
                      onClick={handleFTPicker}
                    >
                      <Box
                        className={classes.color}
                        style={{ background: props.configDetails.ftColor }}
                      />
                    </Box>
                    {displayColorPicker.ftColor ? (
                      <Box className={classes.popover}>
                        <Box
                          className={classes.cover}
                          onClick={handleFTPicker}
                        />
                        <SketchPicker
                          key="ftColorPicker"
                          color={props?.configDetails?.ftColor}
                          onChange={handleFTColor}
                        />
                      </Box>
                    ) : null}
                  </Box>
                </Box>
                  </Box>
                  <Box>
                    <Box paddingBottom="6px">
                      <Typography className={classes.inputLabel}>
                        Background Color
                      </Typography>
                      <Box
                        className={classes.swatch}
                        style={{ 
                          background: props.configDetails.bgColor,
                          cursor:props?.configDetails?.bgOption==="bgColor"?"pointer":"not-allowed" 
                        }}

                        onClick={(e)=>{
                          if (props?.configDetails?.bgOption==="bgColor"){
                            handleBGPicker()
                          }
                        }}
                      >
                        <Box
                          className={classes.color}
                          style={{ background: props.configDetails.bgColor }}
                        />
                      </Box>
                      {displayColorPicker.bgColor ? (
                        <Box className={classes.popover}>
                          <Box
                            className={classes.cover}
                            onClick={handleBGPicker}
                          />
                          <SketchPicker
                            key="bgColorPicker"
                            color={props.configDetails.bgColor}
                            onChange={handleBGColor}
                          />
                        </Box>
                      ) : null}
                      <FormHelperText  style={{ color:"red"}}>
                        {props.configDetails.bgOption==="bgColor" && (props.configDetails.bgColor === undefined ||
                        props.configDetails.bgColor === "")
                          ? props.ErrorMessage
                          : ""}
                      </FormHelperText>
                    </Box>
                    <Box paddingBottom="6px">
                      <Typography className={classes.inputLabel}>
                        Foreground Color
                      </Typography>
                      <Box
                        className={classes.swatch}
                        style={{ background: props.configDetails.fgColor }}
                        onClick={handleFGPicker}
                      >
                        <Box
                          className={classes.color}
                          style={{ background: props.configDetails.fgColor }}
                        />
                      </Box>
                      {displayColorPicker.fgColor ? (
                        <Box className={classes.popover}>
                          <Box
                            className={classes.cover}
                            onClick={handleFGPicker}
                          />
                          <SketchPicker
                            key="fgColorPicker"
                            color={props.configDetails.fgColor}
                            onChange={handleFGColor}
                          />
                        </Box>
                      ) : null}
                      <FormHelperText  style={{ color:"red"}}>
                        {props.configDetails.fgColor === undefined ||
                        props.configDetails.fgColor === ""
                          ? props.ErrorMessage
                          : ""}
                      </FormHelperText>
                    </Box>
                  </Box>
                  <Box>
                    <Box paddingBottom="6px">
                      <Typography className={classes.inputLabel}>
                        Highlighting Color
                      </Typography>
                      <Box
                        className={classes.swatch}
                        style={{ background: props.configDetails.hgColor }}
                        onClick={handleHGPicker}
                      >
                        <Box
                          className={classes.color}
                          style={{ background: props.configDetails.hgColor }}
                        />
                      </Box>
                      {displayColorPicker.hgColor ? (
                        <Box className={classes.popover}>
                          <Box
                            className={classes.cover}
                            onClick={handleHGPicker}
                          />
                          <SketchPicker
                            key="hgColorPicker"
                            color={props.configDetails.hgColor}
                            onChange={handleHGColor}
                          />
                        </Box>
                      ) : null}
                      <FormHelperText  style={{ color:"red"}}>
                        {props.configDetails.hgColor === undefined ||
                        props.configDetails.hgColor === ""
                          ? props.ErrorMessage
                          : ""}
                      </FormHelperText>
                    </Box>
                    <Box paddingBottom="6px">
                      <Typography className={classes.inputLabel}>
                        Gradient Color
                      </Typography>
                      <Box
                      
                        className={classes.swatch}
                        style={{ 
                          background: props.configDetails.gdColor,
                          cursor:props?.configDetails?.bgOption==="gdColor"?"pointer":"not-allowed"
                        }}
                        onClick={(e)=>{
                          if (props?.configDetails?.bgOption==="gdColor"){
                            handleGDPicker()
                          }
                        }}
                      >
                        <Box className={classes.color} />
                      </Box>
                      {displayColorPicker.gdColor ? (
                        <Box className={classes.popover}>
                          <Box
                            className={classes.cover}
                            onClick={handleGDPicker}
                          />
                          <ColorPickerComponent
                            key="gdColorPicker"
                            color={props.configDetails.gdColor}
                            onChange={handleGDColor}
                          />
                        </Box>
                      ) : null}
                      <FormHelperText  style={{ color:"red"}}>
                        {props.configDetails.bgOption==="gdColor" && (props.configDetails.gdColor === undefined ||
                        props.configDetails.gdColor === "")
                          ? props.ErrorMessage
                          : ""}
                      </FormHelperText>
                    </Box>
                  </Box>
                </Box>
                <Box>
              <Typography style={{marginBottom:"10px"}}  className={classes.inputlabel}>Background Image</Typography>
                    <Box style={{display:"flex" , gap:"10px"}}>
                    <Box paddingBottom="5px">

<label htmlFor="16x9" style={{ cursor: "pointer" }}>
  <Input
    style={{ display: "none" ,
             cursor:  props?.configDetails?.bgOption==="bgImage"?"pointer":"not-allowed"
    }}
    id="16x9"
    name="16x9"
    type="file"
    multiple
    onChange={imageHandler}
    required
    inputProps={{ accept: "image/*" }}
    fullWidth
    disabled =  {props?.configDetails?.bgOption==="bgImage"?false:true}
  />
  <Box   style={{
                                                    cursor:  props.configDetails.bgOption==="bgImage"?"pointer":"not-allowed"
                        }} className={classes.logofield} display="flex" alignItems="center" justifyContent="space-evenly">
    <Box>
      <Typography className={classes.logoTypo}>16x9</Typography>
    </Box>
  </Box>

</label>
{
    imageInputError["16x9"] && <FormHelperText  style={{ color:"red"}}>{imageInputError["16x9"]}</FormHelperText>
}
{
  props.configDetails.bgOption==="bgImage" && <FormHelperText  style={{ color:"red"}}>
  {  ( props?.configDetails.bgImage?.["16x9"] ===
                undefined ||
                props?.configDetails.bgImage?.["16x9"] === "")
                &&  props.ErrorMessage
                 }
            </FormHelperText>
}

{
 props?.configDetails.bgImage?.["16x9"] &&
  <Box className={classes.Image16}>      
  
  <img className={classes.Image16} src={props?.configDetails.bgImage?.["16x9"]}></img>
</Box>
}

</Box>
<Box paddingBottom="5px">

<label htmlFor="1x1" style={{ cursor: "pointer" }}>
  <Input
 style={{ display: "none" ,
  cursor:  props?.configDetails?.bgOption==="bgImage"?"pointer":"not-allowed"
}}
    id="1x1"
    name="1x1"
    type="file"
    multiple
    onChange={imageHandler}
    required
    inputProps={{ accept: "image/*" }}
    fullWidth
    disabled =  {props?.configDetails?.bgOption==="bgImage"?false:true}
  />
  <Box style={{
                                                    cursor:  props.configDetails.bgOption==="bgImage"?"pointer":"not-allowed"
                        }}  className={classes.logofield} display="flex" alignItems="center" justifyContent="space-evenly">
    <Box>
      <Typography className={classes.logoTypo}>9x16</Typography>
    </Box>
  </Box>
</label>
{
    imageInputError["1x1"] && <FormHelperText  style={{ color:"red"}}>{imageInputError["1x1"]}</FormHelperText>
}
{
  props.configDetails.bgOption==="bgImage" && <FormHelperText  style={{ color:"red"}}>
  {  ( props?.configDetails.bgImage?.["1x1"] ===
                undefined ||
                props?.configDetails.bgImage?.["1x1"] === "")
                &&  props.ErrorMessage
                 }
            </FormHelperText>

}


{
 props?.configDetails.bgImage?.["1x1"] &&
  <Box className={classes.Image9x16}>      
  <img className={classes.Image9x16} src={props?.configDetails.bgImage?.["1x1"]}></img>
</Box>
}

</Box>

                    </Box>

                    <Typography style={{marginBottom:"10px"}}  className={classes.inputlabel}>Loader image</Typography>
                    <Box style={{display:"flex"}}>
                    <Box paddingBottom="5px">




</Box>
<Box paddingBottom="5px">

<label htmlFor="1x1loaderImage" style={{ cursor: "pointer" }}>
  <Input
 style={{ display: "none" ,
  // cursor:  props?.configDetails?.bgOption==="bgImage"?"pointer":"not-allowed"
}}
    id="1x1loaderImage"
    name="1x1loaderImage"
    type="file"
    multiple
    onChange={imageHandler}
    required
    inputProps={{ accept: "image/*" }}
    fullWidth
    // disabled =  {props?.configDetails?.bgOption==="bgImage"?false:true}
  />
  <Box style={{
                                                    // cursor:  props.configDetails.bgOption==="bgImage"?"pointer":"not-allowed"
                        }}  className={classes.logofield} display="flex" alignItems="center" justifyContent="space-evenly">
    <Box>
      <Typography className={classes.logoTypo}>1x1</Typography>
    </Box>
  </Box>
</label>
{
    imageInputError["1x1loaderImage"] && <FormHelperText  style={{ color:"red"}}>{imageInputError["1x1loaderImage"]}</FormHelperText>
}
{
   <FormHelperText  style={{ color:"red"}}>
  {  ( props?.configDetails.loaderImage?.["1x1"] ===
                undefined ||
                props?.configDetails.loaderImage?.["1x1"] === "")
                &&  props.ErrorMessage
                 }
            </FormHelperText>

}


{
   props?.configDetails.loaderImage?.["1x1"] &&


  <Box className={classes.Image1x1}>      
  <img className={classes.Image1x1} src={props?.configDetails.loaderImage?.["1x1"]}></img>
</Box>
}

</Box>

  </Box>


                   
              
              <Box>
  <Typography style={{ marginBottom: "10px" }}>See More Icon</Typography>
  <FormControl component="fieldset">
    <RadioGroup row value={selectedIcon} onChange={handleChange}>
      
      <Box display="flex" alignItems="center" flexDirection="column">
        <FormControlLabel
          value="back"
          control={<Radio />}
          label={<NavigationIcon fontSize="large" style={{ transform: 'rotate(90deg)', color: '#000' }} />} 
          labelPlacement="top"
        />
      </Box>

      <Box display="flex" alignItems="center" flexDirection="column">
        <FormControlLabel
          value="forward"
          control={<Radio />}
          label={<StopIcon fontSize="large" style={{ color: '#000' }} />} 
          labelPlacement="top"
        />
      </Box>

    </RadioGroup>
  </FormControl>
</Box>

</Box>


        



<Box paddingBottom="20px">
  <Typography className={classes.inputLabel}>Font</Typography>
  <FormControl
    variant="outlined"
    size="small"
    className={classes.textfield}
    fullWidth
    style={{ width: "50%" }}
    required
  >
    <InputLabel id="font-select-label">Select Font</InputLabel>
    <Select
      labelId="font-select-label"
      value={props.configDetails.font ?? ""}
      onChange={handleFont}
      label="Select Font"
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",  
          horizontal: "left",  
        },
        transformOrigin: {
          vertical: "top", 
          horizontal: "left",
        },
        getContentAnchorEl: null  
      }}
    >
      <MenuItem value="Osaka-Sans Serif">Osaka-Sans Serif</MenuItem>
      <MenuItem value="Roboto">Roboto</MenuItem>
      <MenuItem value="Cream Wish">Cream Wish</MenuItem>
      <MenuItem value="Custom">Custom</MenuItem>
    </Select>
    <FormHelperText style={{ color: "red" }}>
      {props.configDetails.font === undefined || props.configDetails.font === ""
        ? props.ErrorMessage
        : ""}
    </FormHelperText>
  </FormControl>
</Box>




                <Box paddingBottom="20px">
                  <Typography
                    id="Select Language"
                    className={classes.inputLabel}
                  >
                    Language
                  </Typography>
                  <Select
                    labelId="Select Languages"
                    multiple
                    className={classes.textfield}
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ width: "50%" }}
                    onChange={handleLanguage}
                    value={
                      props.configDetails?.enabledLanguages?.flatMap(
                        Object.keys,
                      ) ?? Language
                    }
                    MenuProps={MenuProps}
                  >
                    {options.map((x, i) => {
                      return (
                        <MenuItem value={x} key={i}>
                          {x}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText  style={{ color:"red"}}>
                    {props?.configDetails?.enabledLanguages?.length <= 0 ||
                    props?.configDetails?.enabledLanguages === undefined
                      ? props.ErrorMessage
                      : ""}
                  </FormHelperText>
                </Box>

                <Box paddingBottom="20px">
                <Typography id="content-badges" className={classes.inputLabel}>
                  Content Badges 
                </Typography>
                <Box style={{display:"flex" , gap:"20px" , alignItems:"center"}}>
                <Box>
                <TextField
           id="contentBadge-key"
                  value={contentBadge?.key || ""}
                  onChange={handleContentBadgeType}
                  select
                  variant="outlined"
                  style={{minWidth: "100px", backgroundColor: "#FFF", display: "flex", width:"50%"}}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }
                  }}
                >
                  <MenuItem value={"SVOD"}>
                        SVOD
                  </MenuItem>
                  <MenuItem value={"PPV"}>
                       PPV
                  </MenuItem>
                  <MenuItem value={"TVOD"}>
                      TVOD
                  </MenuItem>
                  <MenuItem value={"CUSTOM"}>
                      CUSTOM
                  </MenuItem>
                  </TextField>
                  <FormHelperText  style={{ color:"red"}}>
                        {contentBadge?.error && (contentBadge?.key === undefined ||
                        contentBadge?.key === "")
                          ?contentBadge?.error:""
                          }
                      </FormHelperText>      
                </Box>
                <Box>
<TextField
              id="contentBadge-position"
              value={contentBadge?.position || ""}
              onChange={handleContentBadgePosition}
              select
              variant="outlined"
              style={{minWidth: "200px", backgroundColor: "#FFF", display: "flex", width:"50%"}}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }
              }}
            >
              <MenuItem value={"TR"}>
                    TOP RIGHT
              </MenuItem>
              <MenuItem value={"TL"}>
                    TOP LEFT
              </MenuItem>
              <MenuItem value={"BL"}>
                    BOTTOM  LEFT
              </MenuItem>
              <MenuItem value={"BR"}>
                    BOTTOM RIGHT
              </MenuItem>
              </TextField>
              <FormHelperText  style={{ color:"red"}}>
                        {contentBadge?.error && (contentBadge?.position === undefined ||
                        contentBadge?.position === "")
                          ? contentBadge?.error:""
                          }
                      </FormHelperText>   
                 
                </Box>
                <Box style={{ display: "flex", gap: "10px" }}>
                  <Box paddingBottom="5px" style={{display:"flex" , gap:"10px"}}>
                    <div>
                    <label htmlFor="ContentBadge-Image" style={{ cursor: "pointer" }}>
                      <Input
                        style={{ 
                          display: "none",
                          cursor:"pointer"
                         }}
                        id="ContentBadge-Image"
                        name="ContentBadge-Image"
                        type="file"
                        multiple
                        onChange={imageHandler}
                        required
                        inputProps={{ accept: [".png",".gif"] }}
                        fullWidth                    
                      />
                       {contentBadge?.imageUrl ? (
                      <Box key="contentbadge-upload-input" className={classes.ContentBadgeImage}>
                        <img
                          className={classes.ContentBadgeImage}
                          src={
                            contentBadge?.imageUrl
                          }
                        ></img>
                      </Box>
                       ):
                       <>
                       <Box
                      key={"contentbadge-preview-input"}
                      className={classes.logofield}
                      style={{
                    cursor:"pointer"
                      }}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Box>
                        <Typography className={classes.logoTypo}>
                          Upload Image
                        </Typography>
                      </Box>
                    </Box>
                     <FormHelperText  style={{ color:"red"}}>
                     {contentBadge?.error && (contentBadge?.imageUrl === undefined ||
                     contentBadge?.imageUrl === "")
                     ? contentBadge?.error:""
                       }
                   </FormHelperText>  
                   </>
                      
                    }
                      
                    </label>
                    {
            imageInputError["ContentBadge-Image"] && <FormHelperText  style={{ color:"red"}}>{imageInputError["ContentBadge-Image"]}</FormHelperText>
}
</div> 
                   
                  </Box>
               
                </Box>
                <div  >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) ",
                  borderRadius: "50%",
                  height: "32px",
                  width: "32px",
                  cursor:'pointer'
                }}
                onClick={handleAddContentBadge}
               >

                <img style={{ width: "11.6px", height: "11.6px" }} src={AddSign} />
              </span>
                </div>        
                </Box>   
                {props?.configDetails?.contentBadges?.length>0 &&
                <div style={{display:"flex" , flexDirection:"column" , gap:"10px",marginTop:"10px"}}>
                { props?.configDetails?.contentBadges?.map((value,i)=>{
                  
                  return (
                    <div key={i} style={{display:"flex" , gap:"20px" , alignItems:"center"}}>
                      <TextField
              id="key"
              value={value["key"] ?? ""}
              onChange={(e)=>{updateContentBadgeType(e,i)}}
              select
              variant="outlined"
              style={{width: "100px", backgroundColor: "#FFF", display: "flex"}}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }
              }}
            >
                  <MenuItem value={"SVOD"}>
                        SVOD
                  </MenuItem>
                  <MenuItem value={"PPV"}>
                       PPV
                  </MenuItem>
                  <MenuItem value={"TVOD"}>
                      TVOD
                  </MenuItem>
                  <MenuItem value={"CUSTOM"}>
                      CUSTOM
                  </MenuItem>
              </TextField>
              <TextField
              id="position"
              value={value["position"] ?? ""}
              onChange={(e)=>{updateContentBadgePosition(e,i)}}
              select
              variant="outlined"
              style={{minWidth: "200px", backgroundColor: "#FFF"}}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }
              }}
            >
               <MenuItem value={"TR"}>
                    TOP RIGHT
              </MenuItem>
              <MenuItem value={"TL"}>
                    TOP LEFT
              </MenuItem>
              <MenuItem value={"BL"}>
                    BOTTOM  LEFT
              </MenuItem>
              <MenuItem value={"BR"}>
                    BOTTOM RIGHT
              </MenuItem>
              </TextField> 
              <label htmlFor="ContentBadge-Image-reupload" style={{ cursor: "pointer" }}>
                      <Input
                        style={{ 
                          display: "none",
                          cursor:"pointer"
                         }}
                        id="ContentBadge-Image-reupload"
                        name="ContentBadge-Image-reupload"
                        type="file"
                        multiple
                        onChange={imageHandler}
                        required
                        inputProps={{ accept: [".png",".gif"] }}
                        fullWidth                    
                      />
                    
                      <Box  key="ContentBadge-Image-reupload" className={classes.ContentBadgeImage}>
                        <img
                          id={"imageUrl"}
                          className={classes.ContentBadgeImage}
                          src={
                           value["imageUrl"] ?? ""
                          }
                        />
                      </Box>
                
                </label>

<span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  border:'1px solid',
                  height: "32px",
                  width: "32px",
                  cursor:'pointer'
                }}
                onClick={e=>handleDeleteContentBadge(i)}
               >
                <img style={{ width: "15px", height: "15px" }} src={CloseIcon} />
              </span>
                       
                </div>
                  )
                    
                })}
              
              </div>  
                }
                  
              </Box>

                <Box paddingBottom="20px">
                  <Typography
                    id="Select Metatags"
                    className={classes.inputLabel}
                  >
                    {" "}
                    Meta Tags
                  </Typography>
                  <TextField
                    onChange={handleFilter}
                    fullWidth
                    style={{ maxWidth: "50%" }}
                    className={classes.textfield}
                    variant="outlined"
                    size="small"
                    onKeyUp={handleKeypress}
                    value={newText ?? ""}
                    id="Tags"
                  />
                  <FormHelperText  style={{ color:"red"}}>
                    {!props?.configDetails?.tags?.length && props?.ErrorMessage}
                  </FormHelperText>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    paddingBottom: pTag,
                  }}
                >
                  {props?.configDetails?.tags?.length ? (
                    <>
                      {props?.configDetails?.tags.map((x, index) => {
                        return (
                          <Chip
                            label={x}
                            key={index}
                            onDelete={() => handleDelete(x)}
                            color="primary"
                          ></Chip>
                        );
                      })}
                    </>
                  ) : metaTags.length > 0 ? (
                    <>
                      {metaTags.map((x, index) => {
                        return (
                          <Chip
                            label={x}
                            key={index}
                            onDelete={() => handleDelete(x)}
                            color="primary"
                          ></Chip>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    //allLanguage:state.ProjectReducer.allLanguage
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLanguage: () => {
      dispatch(getAllLanguage());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettings);
