//Layout for the App

import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useJwt, decodeToken } from "react-jwt";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "../assets/img/Logo-White.png";
import Vivre_logo from "../assets/img/vivre-logo.png";
import { connect } from "react-redux";
import { getAllProjectAction, getFeatureConfigAction } from "../screens/Projects/actions/projectActions";
import { SuccessfullyLoginAction } from "../screens/signin/actions/loginAction";
import { Platforms } from "components/PlatformScreensView/PlatformSelector";
import { isDev } from "utils/getEnvironment";
import { cacheImages } from "utils/cacheImages";
import Footer from "../components/Footer/Footer";

let ps;

//Contains Routes in it to display view
let clasn;
const switchRoutes = (
  <Switch>
    {/*All the routes are mapped to open the different views in the app */}
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return <Route exact path={prop.layout + prop.path} component={prop.component} key={key} />;
      }
      return null;
    })}
    <Redirect from="/admin" to="/admin/projects" />
  </Switch>
);

const useStyles = makeStyles(styles);

const Admin = (props, { ...rest }) => {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.useRef();

  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [drawerStatus, setDrawerStatus] = React.useState(false);

  // const [color, setColor] = React.useState("blue");
  //const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const color = "blue";
  /* const handleImageClick = (image) => {
    setImage(image);
  };

 const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  }; */

  //Auth Token Decode when it will be in localStorage
  console.log(setDrawerStatus,drawerStatus,"props.setDrawerStatuss")

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      const decode = decodeToken(localStorage.getItem("token"));
      props.SuccessfullyLoginAction(decode);
    }
  }, [localStorage.getItem("token")]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  useEffect(() => {
    props.getAllProjectAction();
  }, []);

  useEffect(() => {
    if(typeof props?.authToken?.t_id ==="string"){
      
      props.getFeatureconfig(props.authToken.t_id);
    }
  }, [props?.authToken?.t_id]);
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel, localStorage.getItem("token")]);
  
  React.useEffect(() => {
    const imgs = Platforms.map(
      (platform) => `${process.env.REACT_APP_BASE_ASSETS_URL}${isDev() ? "" : "/assets"}/platformIcons/${platform}.svg`
    );

    cacheImages(imgs);
  }, []);

  if (localStorage.getItem("token")) {
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText={"Creative Tim"}
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          drawerStatus={drawerStatus}
          setDrawerStatus={setDrawerStatus}
          ProjectDetails={props.projectDetails}
          allProjects={props.allProjects}
          name={props?.authToken?.name}
          {...rest}
        />
        {drawerStatus ? (
          <div className={classes.mainPanel} ref={mainPanel}>
            <Navbar
              routes={routes}
              handleDrawerToggle={handleDrawerToggle}
              drawerStatus={drawerStatus}
              setDrawerStatuss={setDrawerStatus}
              ProjectDetails={props.projectDetails}
              allProjects={props.allProjects}
              logo={logo}
              userName={props?.authToken?.name}
              {...rest}
            />
            {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}

            {getRoute() ? (
              <div className={classes.content}>
                <div className={classes.container}>{switchRoutes}</div>
              </div>
            ) : (
              <div className={classes.map}>{switchRoutes}</div>
            )}
          </div>
        ) : (
          <div className={classes.mainPanelOp} ref={mainPanel}>
            <Navbar
              routes={routes}
              handleDrawerToggle={handleDrawerToggle}
              drawerStatus={drawerStatus}
              setDrawerStatuss={setDrawerStatus}
              ProjectDetails={props.projectDetails}
              allProjects={props.allProjects}
              userName={props?.authToken?.name}
              logo={logo}
              {...rest}
            />
            {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
            {getRoute() ? (
              <div className={classes.content}>
                <div className={classes.container}>{switchRoutes}</div>
              </div>
            ) : (
              <div className={classes.map}>{switchRoutes}</div>
            )}
          </div>
        )}
      
    
      </div>
    );
  } else {
    return <Redirect from="/" to="/signin" />;
  }
};

const mapStateToProps = (state) => {
  return {
    projectDetails: state.ProjectReducer.projectDetails,
    allProjects: state.ProjectReducer.allProjects,
    authToken: state.loginReducer.authTokens,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllProjectAction: () => {
      dispatch(getAllProjectAction());
    },
    getFeatureconfig: (t_id) => {
      dispatch(getFeatureConfigAction(t_id));
    },
    SuccessfullyLoginAction: (decode) => {
      dispatch(SuccessfullyLoginAction(decode));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
