import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  { "timeline": "2024-08-01", "total_users": 1000 },
  { "timeline": "2024-08-02", "total_users": 1200 },
  { "timeline": "2024-08-03", "total_users": 1400 },
  { "timeline": "2024-08-04", "total_users": 1300 },
  { "timeline": "2024-08-05", "total_users": 1700 },
  { "timeline": "2024-08-06", "total_users": 1500 },
  { "timeline": "2024-08-07", "total_users": 1200 },
  { "timeline": "2024-08-08", "total_users": 1600 },
  { "timeline": "2024-08-09", "total_users": 1900 },
  { "timeline": "2024-08-10", "total_users": 2000 },
  { "timeline": "2024-08-11", "total_users": 1800 },
  { "timeline": "2024-08-12", "total_users": 2200 },
  { "timeline": "2024-08-13", "total_users": 2100 },
  { "timeline": "2024-08-14", "total_users": 2300 },
  { "timeline": "2024-08-15", "total_users": 1900 },
  { "timeline": "2024-08-16", "total_users": 2400 },
  { "timeline": "2024-08-17", "total_users": 2600 },
  { "timeline": "2024-08-18", "total_users": 2200 },
  { "timeline": "2024-08-19", "total_users": 2800 },
  { "timeline": "2024-08-20", "total_users": 3000 },
  { "timeline": "2024-08-21", "total_users": 2900 },
  { "timeline": "2024-08-22", "total_users": 3100 },
  { "timeline": "2024-08-23", "total_users": 3200 },
  { "timeline": "2024-08-24", "total_users": 3300 },
  { "timeline": "2024-08-25", "total_users": 3500 },
  { "timeline": "2024-08-26", "total_users": 3400 },
  { "timeline": "2024-08-27", "total_users": 3700 },
  { "timeline": "2024-08-28", "total_users": 3900 },
  { "timeline": "2024-08-29", "total_users": 4000 },
  { "timeline": "2024-08-30", "total_users": 3800 },
];

const TotalUsersAreaChart = () => {
  return (
    <ResponsiveContainer width="100%" >
      <AreaChart
        data={data}
        margin={{
          top: 10, right: 30, left: 0, bottom: 0,
        }}
      >
        
        <XAxis dataKey="timeline" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="total_users" stroke="#8884d8" fill="#8884d8" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default TotalUsersAreaChart;
