

import React from 'react';
import { Box, Typography, List, ListItem, Link } from '@mui/material';

const TearmsAndCondition = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#d8e5ff',
        padding: { xs: '20px', md: '50px 20px' },
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          maxWidth: '900px',
          backgroundColor: '#d8e5ff',
          padding: { xs: '20px', md: '40px' },
          borderRadius: '10px',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
          lineHeight: 1.8,
          color: '#333',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '2em', md: '2.5em' },
            color: '#002b5c',
            textAlign: 'center',
            marginBottom: '40px',
            fontWeight: 'bold',
          }}
        >
          Terms and Conditions
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
          <strong>Effective date:</strong> 3 Sep 2024
        </Typography>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.5em', md: '1.75em' },
            color: '#003366',
            marginBottom: '20px',
            fontWeight: '600',
          }}
        >
          Introduction
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
          This document is an electronic record of the Information Technology Act, 2000 ("IT Act"), which mandates for publishing of rules and regulations, privacy policy, and terms of use for access or usage of the Product.
        </Typography>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.5em', md: '1.75em' },
            color: '#003366',
            marginBottom: '20px',
            fontWeight: '600',
          }}
        >
          Acceptance of Terms
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
          By using the Product, you agree to the terms outlined in this document. If you are using the Product on behalf of an organization, you confirm that you have the authority to bind that organization to these Terms.
        </Typography>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.5em', md: '1.75em' },
            color: '#003366',
            marginBottom: '20px',
            fontWeight: '600',
          }}
        >
          Intellectual Property Rights
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
          The content on Vivre app such as text, software, scripts, graphics, photos, sounds, videos, and other materials are owned by or licensed to Horizon Broadband and are protected under applicable laws. You may not use or exploit this content without prior written consent.
        </Typography>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.5em', md: '1.75em' },
            color: '#003366',
            marginBottom: '20px',
            fontWeight: '600',
          }}
        >
          Content Usage
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
          Content on the Vivre app is provided "AS IS" for professional use and cannot be used, copied, or distributed for any other purpose without prior written permission.
        </Typography>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.5em', md: '1.75em' },
            color: '#003366',
            marginBottom: '20px',
            fontWeight: '600',
          }}
        >
          Modifications and Amendments
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
          We may update these Terms from time to time to reflect changes in law or our practices. Continued use of the Product means you accept the updated Terms.
        </Typography>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.5em', md: '1.75em' },
            color: '#003366',
            marginBottom: '20px',
            fontWeight: '600',
          }}
        >
          Contact Information
        </Typography>

        <Box
          sx={{
            marginTop: '40px',
            padding: '20px',
            backgroundColor: '#d8e5ff',
            borderLeft: '5px solid #003366',
            borderRadius: '5px',
            color: '#555',
          }}
        >
          <Typography variant="body1">
            If you have any questions regarding these Terms, please contact us at:
          </Typography>
          <Typography variant="body1" sx={{ marginTop: '10px' }}>
            Email: <Link href="mailto:support@horizonbroadband.com">support@horizonbroadband.com</Link>
          </Typography>
          <Typography variant="body1">Phone: +1-800-555-1234</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TearmsAndCondition;
