
import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import ProjectIcon from "assets/img/headNavLogo/projectsIcons.svg";
import WidgetIcon from "assets/img/sideNavIcons/WidgetManager.svg";
import AdminIcon from "assets/img/sideNavIcons/Admin.svg";
import ScreenIcon from "assets/img/sideNavIcons/Screens.svg";
import createicon from '../../../assets/img/createicon.png';
import editicon from '../../../assets/img/editicon.png';

const Help = () => {
  const [playingVideo, setPlayingVideo] = useState(null);
  const [forcePlay, setForcePlay] = useState(false); 

  useEffect(() => {
    if (playingVideo !== null) {
      setForcePlay(true); 
    }
  }, [playingVideo]);

  const handlePlay = (index) => {
    setPlayingVideo(index); 
    setForcePlay(false); 
  };

  const sections = [
    {
      title: 'Projects',
      icon: ProjectIcon,
      tiles: [
        { 
          text: 'Create Project', 
          url: 'https://www.youtube.com/watch?v=VFj2iPrF8WU', 
          image: createicon, 
          description: 'Learn how to create a new project from scratch.' 
        },
        { 
          text: 'Edit Project', 
          url: 'https://www.youtube.com/watch?v=VFj2iPrF8WU', 
          image: editicon, 
          description: 'Steps to edit an existing project.' 
        },
        { 
          text: 'Delete Project', 
          url: 'https://www.youtube.com/watch?v=VFj2iPrF8WU', 
          description: 'How to delete an already existing project.' 
        },
      ],
    },
    {
      title: 'Screens',
      icon: ScreenIcon,
      tiles: [
        { 
          text: 'Create Screen', 
          url: 'https://youtu.be/WS-hN3SfmVE', 
          description: 'Create a new screen and configure settings.' 
        },
        { 
          text: 'Publish/Unpublish Screen', 
          url: 'https://www.youtube.com/watch?v=VFj2iPrF8WU', 
          description: 'Learn how to publish or unpublish a screen.' 
        },
        { 
          text: 'Reorder screen', 
          url: 'https://youtu.be/W67uBe1Wcyc?si=aZDkryY83ATjmFWf', 
          description: 'How to reorder screens in your project.' 
        },
        { 
          text: 'Export screen', 
          url: 'https://youtu.be/qKlyQyXUApc?si=3oOcziL2qo0G-hUU', 
          description: 'How to export screens in your project.' 
        }
      ],
    },
    {
      title: 'Widgets',
      icon: WidgetIcon,
      tiles: [
        { 
          text: 'Create Widget', 
          url: 'https://www.youtube.com/watch?v=VFj2iPrF8WU', 
          description: 'Learn how to create widgets for your project.' 
        },
        { 
          text: 'Add Widget', 
          url: 'https://youtu.be/tQyH2JT830E?si=vQnG8U8qwFZSGWjH', 
          description: 'Steps to add a widget to a screen.' 
        },
        { 
          text: 'Reorder widget', 
          url: 'https://youtu.be/ED3ha2_7bjk?si=MqeNTDBnQ0lzqF-P', 
          description: 'How to reorder widgets in your screen.' 
        },
      ],
    },
    {
      title: 'Admin',
      icon: AdminIcon,
      tiles: [
        { 
          text: 'Create Admin', 
          url: 'https://www.youtube.com/watch?v=VFj2iPrF8WU', 
          description: 'Guide to creating an admin account.' 
        },
        { 
          text: 'Delete Admin', 
          url: 'https://www.youtube.com/watch?v=VFj2iPrF8WU', 
          description: 'Learn how to delete an admin from the system.' 
        },
      ],
    },
  ];

  const getTileBackgroundColor = (sectionTitle) => {
    switch (sectionTitle) {
      case 'Projects':
        return '#c9c1d5';
      case 'Screens':
        return '#80c280';
      case 'Widgets':
        return '#64c7f078';
      case 'Admin':
        return '#fdfd9c';
      default:
        return '#f0f0f0';
    }
  };

  return (
    <div>
      {sections.map((section, sectionIdx) => (
        <div key={sectionIdx} style={{ marginBottom: '40px' }}>
          <div style={{display: "flex", alignItems: "center"}}>
            <img src={section?.icon} alt="Project Icon" style={{ marginRight: '10px', width: "35px" }} />
            <h3 style={{margin:"10px"}}><b>{section.title}</b></h3>
          </div>
          <div style={{ display: 'flex', gap: '20px' }}>
            {section.tiles.map((tile, tileIdx) => (
              <div key={tileIdx} style={{ textAlign: 'center', width: '300px' }}>
                <ReactPlayer
                  url={tile.url}
                  width="300px"
                  height="170px"
                  playing={playingVideo === `${sectionIdx}-${tileIdx}` && forcePlay}
                  onPlay={() => handlePlay(`${sectionIdx}-${tileIdx}`)}
                  onPause={() => setPlayingVideo(null)}
                  controls
                  style={{
                    borderRadius: '15px',
                    boxShadow: '0px 5px 10px 0px black',
                  }}
                />
                <h4 style={{ fontWeight: 'bold', color: '#333', marginBottom: '5px' }}>
                  {tile.text}
                </h4>
                {tile.description && (
                  <p style={{ fontSize: '14px', fontWeight: 'bold', color: '#444', marginTop: '0', marginBottom: '0' }}>
                    {tile.description}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Help;
