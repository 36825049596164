import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";

// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { TableFooter } from "@material-ui/core";
import DeleteWidgetPopUp from "components/widgetManagerComponents/deleteWidgetPopup"


//Images
import deleteBucket from "../../assets/img/platformScreenVisualization/deleteBucket.svg"


const useStyles = makeStyles(styles);
export default function CustomTable(props) {
  const [expand, setExpand] = React.useState(false);
  const [deleteWidgetPopupOpen,setDeleteWidgetPopUpOpen]=React.useState(false)
  const [widgetName, setWidgetName] = React.useState("");
  const [widgetId, setWidgetId] = React.useState("");
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor, pagination } = props;
  const history = useHistory();


  //Onclick actions
  const expandMoreOrLess = () => {
    setExpand(!expand);
  };

  const selectPlaylistId=(prop)=>{

  }


  const editWidget=(WidgetDetails)=>{
    console.log(WidgetDetails?.item?.playlist?.type,"widget tob echecked");
    localStorage.setItem("PageNumber",props.pageNumber)
    if(WidgetDetails?.item?.playlist?.type == 'K_PDF' || WidgetDetails?.item?.playlist?.type == "ATHENA"){
          // history.push({
          //   pathname: "/admin/widgetmanager/edit",
          //   state: { widgetDetails: WidgetDetails,
          //     prevPage: "WidgetManager" },
          // })
     }
     else{
          history.push({
            pathname: "/admin/widgetmanager/edit",
            state: { widgetDetails: WidgetDetails,
              prevPage: "WidgetManager" },
          })
     }
    
  }


  const deleteWidgetPopup=(widgetDetails)=>{
    setWidgetName(widgetDetails.name)
    setWidgetId(widgetDetails._id)
    setDeleteWidgetPopUpOpen(true)
  }

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
          <TableRow className={classes.tableHeadRow}>
            {tableHead.map((prop, key) => {
             
              return (
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                  key={key}
                >
                  {prop}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        ) : null}
         <TableBody>
          {tableData?.map((prop, key) => {
             //if(prop?.layout===props?.selectedWidgetType||props?.selectedWidgetType=='All'){
            return (
              <TableRow key={key} className={classes.tableBodyRow} >
                
                <TableCell className={classes.tableCell + " " + classes.tableBodyCell} key={key} onClick={()=>editWidget(prop)} >
                      {prop._id.slice(-5)}
                    </TableCell>
                    <TableCell className={classes.tableCell + " "+ classes.tableBodyCell} key={key} onClick={()=>editWidget(prop)}>
                      {prop.name}
                    </TableCell>
                    <TableCell className={classes.tableCell + " " + classes.tableBodyCell} key={key} onClick={()=>editWidget(prop)}>
                      {prop.layout}({prop.kalturaOTTImageType})
            </TableCell>  
                   {prop.layout==='HRO'?<TableCell className={classes.tableCell + " " + classes.tableBodyCell} key={key} onClick={()=>editWidget(prop)}>
                      {prop.item?.landingPage?.playlist?.kalturaChannelId}
                    </TableCell> :<TableCell className={classes.tableCell + " " + classes.tableBodyCell} key={key} onClick={()=>editWidget(prop)}>
                      {prop.item?.playlist?.kalturaChannelId}
                    </TableCell>}
                  {/*  <TableCell className={classes.tableCell + " " + classes.tableBodyCell} key={key}>
                      {prop._id}
                    </TableCell> */}
                    <TableCell className={classes.tableCell + " " + classes.tableBodyCell} key={key} onClick={()=>editWidget(prop)} >
                      {prop.supportedDevices.length}
                    </TableCell>
                    <TableCell className={classes.tableCell + " " + classes.tableBodyCell} key={key} onClick={()=>editWidget(prop)}>
                      {prop.status}
                    </TableCell>
                    <TableCell className={classes.tableCell + " " + classes.tableBodyCell} key={key} onClick={()=>deleteWidgetPopup(prop)}>
                     <img src={deleteBucket} />
                    </TableCell>
               
              </TableRow>
            );
 //} 

 })}
            </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={12} style={{ paddingBottom: "32px" }} >
                {pagination}
              </TableCell>
            </TableRow>
          </TableFooter>
      </Table>
      <DeleteWidgetPopUp
        open={deleteWidgetPopupOpen}
        setRemoveWidgetPopUp={setDeleteWidgetPopUpOpen}
        widgetName={widgetName}
        widgetId={widgetId}
        projectDetails={props.projectDetails}

       
      />
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
