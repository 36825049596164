import {
    Box,
    FormHelperText,
    makeStyles,
    TextField,
    Typography,
    Select,
    MenuItem,
    Chip,
    Card,
    Input,
  } from "@material-ui/core";
  import Switch from '@mui/material/Switch';
  import React from "react";
  import { connect } from "react-redux";
  

  import Style from "../../../../assets/jss/material-dashboard-react/views/addProjectStepperStyle";
  

  
  const useStyle = makeStyles(Style);

  
  const FeatureConfig = (props)=>{
    const classes = useStyle();
    const featureConfig = props?.featureConfig || null
    const appControl = props?.featureConfig?.appControl || null
    
    return (
      <Box>
        <Card className={classes.EditCard}>
          <Box paddingLeft="24px" paddingTop="24px" paddingBottom="24px">
            <Box  pb={3} paddingRight="33px">
              <Box gridGap={"50px"} component="form" role="form">
             <div style={{ cursor:"not-allowed"}}>
             <Typography>Feature Config</Typography>
             <Box margin={"5px"} paddingBottom="10px" style={{display:"flex" , alignItems:"center"}}>
              <div style={{display:"flex", alignItems:"center" ,gap:"5px"}}>
              <Typography style={{marginTop:"9px"}} className={classes.inputLabel}>Sara</Typography>
            
              <Switch disabled defaultChecked={featureConfig?.sara || false} />
        
  
              </div>
              <div style={{display:"flex", alignItems:"center",gap:"5px"}}>
              <Typography style={{marginTop:"9px"}} className={classes.inputLabel}>Sara Insights</Typography>
              <Switch disabled defaultChecked={featureConfig?.saraInsights || false} />
              </div>
              <div style={{display:"flex", alignItems:"center",gap:"5px"}}>
              <Typography style={{marginTop:"9px"}} className={classes.inputLabel}>Sara Search </Typography>
             
              <Switch disabled defaultChecked={featureConfig?.sara || false} />
            
              </div>
              </Box>
             </div>
                {
                  appControl &&<>
                  <Typography>Application Control</Typography>
  
             <div style={{display:"grid",width:"40%",marginTop:"10px" , marginBottom:"10px"}}>
             <Box margin={"3px"} style={{display:"flex",justifyContent:"space-between"}} paddingBottom="10px">
                  <Typography className={classes.inputLabel}>Watch Event</Typography>
                  <TextField
                    className={classes.textfield}
                    fullWidth
                    disabled
                    variant="outlined"
                    size="small"
                    style={{ width: "130px",cursor:"not-allowed"}}
                    value={(parseFloat(appControl?.watched)*100).toFixed(0)+"%"}
                        name='watched'
          id='watched'
                  />
  
                </Box>
                <Box margin={"3px"} style={{display:"flex",justifyContent:"space-between"}} paddingBottom="10px">
                  <Typography className={classes.inputLabel}>Beacause You Watched Event</Typography>
                  <TextField
                    className={classes.textfield}
                    fullWidth
                    disabled
                    variant="outlined"
                    size="small"
                    style={{ width: "130px",cursor:"not-allowed"}}
                    value={(parseFloat(appControl?.byw_watched)*100).toFixed(0)+"%"}
                    name='byw_watched'
                    id='byw_watched'
                  />
  
                </Box>
                <Box margin={"3px"} style={{display:"flex",justifyContent:"space-between"}}>
                  <Typography className={classes.inputLabel}>Live Event</Typography>
                  <TextField
                  disabled
                    className={classes.textfield}
                    fullWidth
                    variant="outlined"
                    size="small"
                    style={{ width: "130px",cursor:"not-allowed"}}
                    value={ (parseFloat(appControl?.watch_live)/6000).toFixed(2)+" Minutes"}
                name='watch_live'
          id='watch_live'
                  />
  </Box>
             </div>
  
  
  
                  </>
                }
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    );
  };
  
  const mapStateToProps = (state) => {
    return {};
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {};
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FeatureConfig);
  