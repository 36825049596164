import {
  Box,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
} from "@material-ui/core";
import { connect } from "react-redux";
import Kaltura from "../../../assets/img/logo45.png";
import Style from "../../../assets/jss/material-dashboard-react/views/addProjectStepperStyle";
import { useState } from 'react';
import { useEffect } from 'react';

const useStyle = makeStyles(Style);

const ProjectConfig = (props) => {
  const classes = useStyle();

  const [cms, setCMS] = useState("");

  const handleCMS = (event) => {
    setCMS(event.target.value);
  };

  const handleApiversion = (event) => {
    props.setConfigDetails({ ...props.configDetails, apiVersion: event.target.value });
  };

  const handlePartnerId = (event, value) => {
    props.setConfigDetails({ ...props.configDetails, partnerId: event.target.value });
  };

  const handleRootCategoryId = (event) => {
    props.setConfigDetails({ ...props.configDetails, rootCategoryId: event.target.value });
  };

  const handleBaseUrl = (event) => {
    props.setConfigDetails({ ...props.configDetails, baseUrl: event.target.value });
  };

  const handleUsername = (event) => {
    props.setConfigDetails({ ...props.configDetails, username: event.target.value });
  };

  const handlePassword = (event) => {
    props.setConfigDetails({ ...props.configDetails, password: event.target.value });
  };

  const handleApplicationName = (event) => {
    props.setConfigDetails({ ...props.configDetails, applicationName: event.target.value });
  };

  const handleClientVersion = (event) => {
    props.setConfigDetails({ ...props.configDetails, clientVersion: event.target.value });
  };

  const handleplatform = (event) => {
    props.setConfigDetails({ ...props.configDetails, platform: event.target.value });
  };

  const handleTag = (event) => {
    props.setConfigDetails({ ...props.configDetails, tag: event.target.value });
  };

  const handleUDID = (event) => {
    props.setConfigDetails({ ...props.configDetails, udid: event.target.value });
  };

  return (
    <Box paddingLeft="24px">
      <Grid>
        <Grid item sm={12} md={6} lg={6}>
          <Box>
            <Box pb={3} paddingRight="33px">
            <Box component="form" role="form">
              <Box paddingBottom="20px">
                <Typography className={classes.inputLabel}>
                  CMS Provider
                </Typography>
                <TextField
                  id="CMS-Provider"
                  value={cms}
                  onChange={handleCMS}
                  select
                  variant="outlined"
                  // label="Select CMS Provider"
                  placeholder="Select CMS Provider"
                  style={{minWidth: "200px", backgroundColor: "#FFF", display: "flex"}}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }
                  }}
                >
                  <MenuItem value={""}>
                      None
                  </MenuItem>
                  <MenuItem value={"CMS Streaming Platform"}>
                    <img src={Kaltura} width="19px" style={{ paddingRight:"12px" }} />
                    CMS Streaming Platform
                  </MenuItem>
                  </TextField>
                </Box>
                {cms ? 
                <>
                  <Box paddingBottom="20px">
                    <Typography className={classes.inputLabel}>
                      API Version
                    </Typography>
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter the API Version"
                      onChange={handleApiversion}
                      required
                      value={props.configDetails.apiVersion ?? ""}
                    />
                    <FormHelperText>
                      {props.configDetails.apiVersion
                        ? ""
                        : props.ErrorMessage}
                    </FormHelperText>
                  </Box>
                  <Box paddingBottom="20px">
                    <Typography className={classes.inputLabel}>
                      Partner Id
                    </Typography>
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter the Partner ID"
                      onChange={handlePartnerId}
                      required
                      value={props.configDetails.partnerId ?? ""}
                    />
                    <FormHelperText>
                      {props.configDetails.partnerId
                        ? ""
                        : props.ErrorMessage}
                    </FormHelperText>
                  </Box>
                  <Box paddingBottom="20px">
                    <Typography className={classes.inputLabel}>
                      Root CatgeoryID
                    </Typography>
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter the Root CatgeoryID"
                      required
                      onChange={handleRootCategoryId}
                      value={props.configDetails.rootCategoryId ?? ""}
                    />
                    <FormHelperText>
                      {props.configDetails.rootCategoryId
                        ? ""
                        : props.ErrorMessage}
                    </FormHelperText>
                  </Box>
                  <Box paddingBottom="20px">
                    <Typography className={classes.inputLabel}>
                      Base URL
                    </Typography>
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter the Base URL"
                      required
                      onChange={handleBaseUrl}
                      value={props.configDetails.baseUrl ?? ""}
                    />
                    <FormHelperText>
                      {props.configDetails.baseUrl
                        ? ""
                        : props.ErrorMessage}
                    </FormHelperText>
                  </Box>
                  <Box paddingBottom="20px">
                    <Typography className={classes.inputLabel}>
                      CMS Username
                    </Typography>
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter the CMS Username"
                      required
                      onChange={handleUsername}
                      value={props.configDetails.username ?? ""}
                    />
                    <FormHelperText>
                      {props.configDetails.username
                        ? ""
                        : props.ErrorMessage}
                    </FormHelperText>
                  </Box>
                  <Box paddingBottom="20px">
                    <Typography className={classes.inputLabel}>
                      CMS Password
                    </Typography>
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter the CMS Password"
                      required
                      onChange={handlePassword}
                      value={props.configDetails.password ?? ""}
                    />
                    <FormHelperText>
                      {props.configDetails.password
                        ? ""
                        : props.ErrorMessage}
                    </FormHelperText>
                  </Box>
                  <Box paddingBottom="20px">
                    <Typography className={classes.inputLabel}>
                      Application Name
                    </Typography>
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter the Application Name"
                      required
                      onChange={handleApplicationName}
                      value={props.configDetails.applicationName ?? ""}
                    />
                    <FormHelperText>
                      {props.configDetails.applicationName
                        ? ""
                        : props.ErrorMessage}
                    </FormHelperText>
                  </Box>
                  <Box paddingBottom="20px">
                    <Typography className={classes.inputLabel}>
                      Client Version
                    </Typography>
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter the Client Version"
                      required
                      onChange={handleClientVersion}
                      value={props.configDetails.clientVersion ?? ""}
                    />
                    <FormHelperText>
                      {props.configDetails.clientVersion
                        ? ""
                        : props.ErrorMessage}
                    </FormHelperText>
                  </Box>
                  <Box paddingBottom="20px">
                    <Typography className={classes.inputLabel}>
                      Platform
                    </Typography>
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter the Platform"
                      required
                      onChange={handleplatform}
                      value={props.configDetails.platform ?? ""}
                    />
                    <FormHelperText>
                      {props.configDetails.platform
                        ? ""
                        : props.ErrorMessage}
                    </FormHelperText>
                  </Box>
                  <Box paddingBottom="20px">
                    <Typography className={classes.inputLabel}>
                      Tag
                    </Typography>
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter the Tag"
                      onChange={handleTag}
                      value={props.configDetails.tag ?? ""}
                    />
                  </Box>
                  <Box paddingBottom="20px">
                    <Typography className={classes.inputLabel}>
                      UDID
                    </Typography>
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter the UDID"
                      required
                      onChange={handleUDID}
                      value={props.configDetails.udid ?? ""}
                    />
                    <FormHelperText>
                      {props.configDetails.udid
                        ? ""
                        : props.ErrorMessage}
                    </FormHelperText>
                  </Box>
                </>
                : null}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectConfig);
