import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import Style from "../../../../assets/jss/material-dashboard-react/views/screen_widgetStyle";
import Screens from "./Screen";
import Widgets from "./Widgets";
import CustomDialog from "components/Dialog/Dialog";
import Snackbar from "components/Snackbar/CustomSnackbar";

import draft from "assets/img/platformScreenVisualization/draft.svg";
import publish from "assets/img/platformScreenVisualization/publish.svg";

import Vector from "../../../../assets/img/icons/svg/Vector.svg";
import Close from "../../../../assets/img/icons/svg/close.svg";
import { connect } from "react-redux";

//Actions
import {
  updateScreenStatusAction,
  exportScreenToPlatformAction,
  publishScreenAction,
} from "../../actions/PlatformAction";
import { isDev } from "utils/getEnvironment";
import ChipTab from "components/chipTab";
import allWidgets from "../../../../assets/img/icons/all_widgets.svg";
import defaultWidgets from "../../../../assets/img/icons/default_widget.svg";
import customWidgets from "../../../../assets/img/icons/custom_widget.svg";
import aiWidgets from "../../../../assets/img/icons/Athena-Logo.svg";

const useStyle = makeStyles(Style);

const widgetTypes = ["All", "Widgets", "Default", "AI"];
const widgetIcons = [allWidgets, customWidgets, defaultWidgets, aiWidgets];

const Screen_Widgets = (props) => {
  const classes = useStyle();

  const initialExportState = {
    IOS_MOB: false,
    AND_MOB: false,
    WEB_MOB: false,
    AND_TAB: false,
    IOS_TAB: false,
    WEB_DES: false,
    AND_TV: false,
    LG_SMART_TV: false,
    SAMSUNG_SMART_TV: false,
  };

  const [value, setValue] = useState("0");
  const [Open, setOpen] = useState(false);
  const [selectExpo, setSelectExpo] = useState(initialExportState);

  const [expoPlatformList, setExpoPlatformList] = useState([]);
  const [openPublish, setOpenPublish] = useState(false);
  const [openUnPublish, setOpenUnpublish] = useState(false);

  const [widgetChip, selectWidgetChip] = useState();

  const exportPlatformArr = JSON.parse(localStorage.getItem("platform"));
  const renderExpo = props?.platforms?.filter((platform) => platform._id.shortName !== exportPlatformArr.shortName);

  const handleExportCard = (value) => {
    setSelectExpo((prev) => ({ ...prev, [value]: !prev[value] }));
  };

  useEffect(()=>{
  let tabValue=localStorage.getItem("tabValue")
  if(tabValue){
  tabValue?setValue(tabValue):setValue("0")
  localStorage.removeItem("tabValue")}
  },[])

  useEffect(()=>{
    console.log("Widget chip",widgetTypes.at(0),props.data)
    selectWidgetChip(widgetTypes.at(0))
  },[props.data?.platform])

  const handleTabs = (event, newValue) => {
    setValue(newValue);
    
  };

  const handleExport = () => {
    props.accessLevel !== "user"
      ? setOpen(true)
      : [
          props.setSnackMessage("You do not have access to export screens."),
          props.setSnackSeverity("warning"),
          props.setSnackOpen(true),
        ];
  };

  const handleClose = () => {
    setOpen(false);
    setSelectExpo(initialExportState);
    setExpoPlatformList([]);
  };

  //Export a screen to other platform api action call and handle export of screen
  const handleConfirmExport = () => {
    const widgets = props?.widgetDetailsArray?.map((widget, index) => {
      return {
        id: widget._id,
        displayOrder: index,
        layout: widget.layout,
      };
    });
    const exportParameters = {
      p_id: props.data.projectId,
      screenName: props.screenName,
      status: props.screenStatus,
      widgets: widgets,
      platforms: expoPlatformList,
    };
    props.exportScreenToPlatformAction(exportParameters);

    setOpen(false);
  };

  const publishScreenDialog = () => {
    props.accessLevel !== "user"
      ? setOpenPublish(true)
      : [
          props.setSnackMessage("You do not have access to publish screens."),
          props.setSnackSeverity("warning"),
          props.setSnackOpen(true),
        ];
  };

  const publishScreen = () => {
    let publishParameters = {
      id: props.screen,
      p_id: props.data.projectId,
      status: "PUBLISHED",
    };

    props.updateScreenStatusAction(publishParameters);
    setOpenPublish(false);
  };

  const unPublishScreen = () => {
    props.accessLevel !== "user"
      ? setOpenUnpublish(true)
      : [
          props.setSnackMessage("You do not have access to save screens as a draft."),
          props.setSnackSeverity("warning"),
          props.setSnackOpen(true),
        ];
  };

  const saveAsDraftScreen = () => {
    const publishScreenDetails = {
      id: props.screen,
      status: "UNPUBLISHED",
      p_id: props?.data?.projectId,
    };

    //Call Update Screen status Api
    props.updateScreenStatusAction(publishScreenDetails);

    setOpenUnpublish(false);
  };

  useEffect(() => {
    const platList = Object.entries(selectExpo)
      .map(([key, value]) => {
        if (value) return key;
      })
      .filter((item) => item !== undefined);
    setExpoPlatformList(platList);
  }, [selectExpo]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("warning");
  return (
    <Box>
            <Snackbar open={snackOpen} message={snackMessage} severity={snackSeverity} setOpen={() => setSnackOpen(false)} />
      <Box display="flex" flexDirection="column">
        <Paper className={classes.paper} elevation={0}>
          <Tabs
            value={value}
            indicatorColor="primary"
            onChange={handleTabs}
            TabIndicatorProps={{ style: { height: "4px", backgroundColor: "#FFFF" } }}
          >
            <Tab value="0" className={classes.unSelectedTab} classes={{ selected: classes.tab }} label="Screens" />
            <Tab value="1" className={classes.unSelectedTab} classes={{ selected: classes.tab }} label="Widgets" />
          </Tabs>
          {value == "0" ? (
            <Screens
              screens={props.allScreens}
              data={props.data}
              setReorderScreen={props.setReorderScreen}
              setScreenDeleted={props.setScreenDeleted}
              setValue={props.setValue}
              projectId={props.projectId}
              platform={props.platform}
              setSnackOpen={props.setSnackOpen}
              setSnackMessage={props.setSnackMessage}
              setSnackSeverity={props.setSnackSeverity}
            />
          ) : (
            ""
          )}
          {value == "1" ? (
            <Box display="flex">
              <Widgets
                onDragStart={props.onDragStart}
                aspectRatio={props.aspectRatio}
                projectId={props.projectId}
                data={props.data}
                setSnackOpen={props.setSnackOpen}
                setSnackMessage={props.setSnackMessage}
                setSnackSeverity={props.setSnackSeverity}
                widgetTypes={widgetChip}
                selectWidgetChip={selectWidgetChip}
                TypeofWidgets={widgetTypes}
              />
              <Box className={classes.chipTabBox}>
                {widgetTypes.map((widget, i) => (
                  <ChipTab
                    title={widget}
                    id={widget}
                   
                    selectChip={widgetChip.includes(widget)}
                    onclick={(event) => {
                      if(widget==="AI"  ){

                        if( props?.featureConfig?.sara === true){
                          selectWidgetChip(event.target.textContent);
                         
                        }else{
                          setSnackOpen(true)
                          setSnackMessage("Current Plan does not support AI Recommendation Feature")
                        }
                
                         }
                         else{
                          selectWidgetChip(event.target.textContent);
                         }
                    }}
                    icon={widgetIcons[i]}
                    key={i}
                  />
                ))}
                <Divider
                
                  orientation="vertical"
                  className={classes.chipIndicator}
                  style={{
                    top: `${-56 * ([...widgetTypes].reverse().indexOf(widgetChip) + 1)}px`,
                  }}
                />
              </Box>
            </Box>
          ) : (
            ""
          )}
        </Paper>
      </Box>
      {
        props.screenName && 
        <Box paddingTop="24px">
        <Paper className={classes.paper2}>
          <Button className={classes.exportButton} onClick={handleExport}>
            <Box paddingTop="24px" paddingLeft="24px">
              <Typography className={classes.Exports}>Export {props.screenName}</Typography>
            </Box>
            <Box paddingTop="24px" style={{ position: "absolute", right: 15 }}>
              <img src={Vector}></img>
            </Box>
          </Button>
          <Divider variant="middle" className={classes.divider} />
          <Box paddingLeft="24px" paddingTop="20px">
            <Button
              variant="contained"
              className={classes.saveButton}
              style={{ width: "361px" }}
              onClick={publishScreenDialog}
            >
              <Typography className={classes.ButtonText}>Publish {props.screenName}</Typography>
            </Button>
          </Box>
          <Box padding="20px" display="flex" justifyContent="center">
            <Button onClick={unPublishScreen} style={{ width: "361px", height: "48px", borderRadius: "24px" }}>
              <Typography className={classes.draftTypo}>Save {props.screenName} as a Draft</Typography>
            </Button>
          </Box>
        </Paper>
      </Box>
      }


      <Dialog open={Open} onClose={handleClose} classes={{ paper: classes.dialog }}>
        <DialogContent className={classes.content}>
          <Box ml="24px" display="flex" flexDirection="row" justifyContent="space-between">
            <Box>
              <Typography className={classes.ExportTypo}>
                Export <span style={{ color: "#0192D4" }}> {props.screenName}</span> to
              </Typography>
            </Box>
            <Button onClick={handleClose} className={classes.dialogButton}>
              <img src={Close}></img>
            </Button>
          </Box>
          <Typography className={classes.ExportHelpText}>
                Exported screen will be published on selected platforms
              </Typography>
          <Box className={classes.dialogWarper}>
            <Grid container spacing={1} className={classes.dialogGrid}>
              {renderExpo?.map((x, i) => {
                return (
                  <Grid item xs={4} key={i}>
                    <Card
                      className={selectExpo[x._id.shortName] ? classes.selectedCard : classes.exportPltCard}
                      onClick={() => handleExportCard(x._id.shortName)}
                    >
                      <Box display="flex" flexDirection="column" justifyContent="space-evenly" alignItems="center">
                        <Box paddingTop="10px" width="75px">
                          <img
                            src={`${process.env.REACT_APP_BASE_ASSETS_URL}${isDev() ? "" : "/assets"}/platformIcons/${
                              x?._id?.shortName
                            }.svg`}
                            width="75px"
                            height="75px"
                          />
                        </Box>
                        <Box>
                          <Typography
                            className={selectExpo[x._id.shortName] ? classes.selectedCardTypo : classes.cardTypo}
                          >
                            {x._id.name}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Box>
            <DialogActions className={classes.action}>
              <Button
                variant="outlined"
                className={classes.cancelButton}
                style={{ width: "180px" }}
                onClick={handleClose}
              >
                <Typography className={classes.cancelTypo}>Cancel</Typography>
              </Button>
              <Button
                variant="contained"
                className={classes.saveButton}
                style={{ width: "180px", marginLeft: "20px" }}
                onClick={handleConfirmExport}
              >
                <Typography className={classes.ButtonText}>Publish All</Typography>
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>

      {/*Dialog for Publish Screen */}

      <CustomDialog
        open={openPublish}
        title={
          <span className={classes.dialogDesc}>
            Are you sure you want to publish <span className={classes.dialogDescFocus}>{props.screenName}</span> screen?
          </span>
        }
        button1="Cancel"
        button2="Yes"
        button1Event={() => setOpenPublish(false)}
        button2Event={() => publishScreen()}
        icon={publish}
      />

      {/*Dialog for unPublish Screen */}

      <CustomDialog
        open={openUnPublish}
        title={
          <span className={classes.dialogDesc}>
            Are you sure you want to save <span className={classes.dialogDescFocus}>{props.screenName}</span> screen as
            a draft?
          </span>
        }
        button1="Cancel"
        button2="Yes"
        button1Event={() => setOpenUnpublish(false)}
        button2Event={() => saveAsDraftScreen()}
        icon={draft}
        margin="2px 0px 0px 10px"
        width="40px"
      />
    </Box>
  );
};

const mapStateToProps = (State) => {
  return {
    platforms: State.screenVisualizationReducer.platforms,
    widgetDetailsArray: State.screenVisualizationReducer.widgetDetailsArray,
    accessLevel: State.loginReducer.authTokens?.accessLevel,
    featureConfig: State.ProjectReducer.featureConfig,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateScreenStatusAction: (details) => {
      dispatch(updateScreenStatusAction(details));
    },
    exportScreenToPlatformAction: (details) => {
      dispatch(exportScreenToPlatformAction(details));
    },
    publishScreenAction: (screenStatusUpdate) => {
      dispatch(publishScreenAction(screenStatusUpdate));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen_Widgets);
