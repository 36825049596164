import {
  RESET_CREATE_PROJECT,
  RESET_UPDATE_PROJECT,
  UPDATE_INTROS,
  UPDATE_SPLASH,
} from "../actionTypes/projectActionsType";
import { ProjectInitialState } from "../initalState/projectState";

export const ProjectReducer = (state = ProjectInitialState, action) => {
  switch (action.type) {
    case "SUCCESSFULLY_CREATE_PROJECT": {
      return {
        ...state,
        createdData: action.payload,
        projectReset: !state.projectReset,
        createReset: !state.createReset,
      };
    }
    case RESET_CREATE_PROJECT: {
      return {
        ...state,
        createReset: !state.createReset,
      };
    }
    case "SUCCESSFULLY_GET_ALL_PROJECTS": {
      return { ...state, allProjects: action.payload };
    }
    case "ADD_PROEJCTNAME_IN_NAVBAR": {
      return {
        ...state,
        projectDetails: action.payload,
        platformReset: !state.platformReset,
      };
    }
    case "GET_ALL_LANGUAGE": {
      return { ...state, loading: true };
    }
    case "SUCCESSFULLY_GET_ALL_LANGUAGE": {
      return { ...state, loading: false, allLanguage: action.payload };
    }
    case "SUCCESSFULLY_CREATE_PROJECT_SETTINGS": {
      return {
        ...state,
        createConfigData: action.payload,
        projectReset: !state.projectReset,
      };
    }
    case "SUCCESSFULLY_UPDATE_PROJECT": {
      return {
        ...state,
        updatedData: action.payload,
        projectReset: !state.projectReset,
        updateReset: !state.updateReset,
      };
    }
    case RESET_UPDATE_PROJECT: {
      return {
        ...state,
        updateReset: !state.updateReset,
      };
    }
    case "SUCCESSFULLY_GET_CMS_CONFIG": {
      return {
        ...state,
        kalturaConfig: action.payload,
      };
    }
    case "SUCCESSFULLY_GET_FEATURECONFIG": {
      return {
        ...state,
        featureConfig: action.payload,
      };
    }
    case UPDATE_INTROS: {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          appConfig: {
            ...state.projectDetails.appConfig,
            introScreens: {
              ...state.projectDetails.appConfig.introScreens,
              [action.payload.platform]: {
                ...state.projectDetails.appConfig.introScreens[
                  action.payload.platform
                ],
                imageUrls: action.payload.intros,
              },
            },
          },
        },
      };
    }
    case UPDATE_SPLASH: {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          splashScreens:{...state.projectDetails.splashScreens,[action.payload.platform]: action.payload.splash},
        },
      };
    }
    default: {
      return state;
    }
  }
};
