import React from 'react';
import TotalPlatformsLineChart from '../../../components/analyticsComponents/charts/TotalPlatformsLineChart';
import { Box, Typography } from '@material-ui/core';
import PlatformsLineChart from 'components/analyticsComponents/charts/PlatformsLineChart';
import TotalUsersAreaChart from 'components/analyticsComponents/charts/TotalUsersAreaChart';
import UsersAreaChart from 'components/analyticsComponents/charts/UsersAreaChart';


const Analytics = () => (
  <Box   style={{gap:"50px" , display:"flex" , flexDirection:"column", minHeight:"100vh"}}>
      <Box display={"flex"} flexDirection={"row"}>

<Box   height={300} width={"50%"}>
   
   <TotalPlatformsLineChart  />
   <Typography style={{textAlign:"center", marginTop:"10px"}}>Total Platforms vs. Timeline</Typography>
 </Box>
 <Box   height={300} width={"50%"}>
   
   <PlatformsLineChart  />
   <Typography style={{textAlign:"center" , marginTop:"10px"}}> Platforms vs. Timeline</Typography>
 </Box>


  </Box>
  <Box display={"flex"} flexDirection={"row"}>
  <Box   height={300} width={"50%"}>
   
   <TotalUsersAreaChart/>
     <Typography style={{textAlign:"center" , marginTop:"10px"}}> Total Users vs. Timeline</Typography>
   </Box>

   <Box   height={300} width={"50%"}>
   <UsersAreaChart/>
     <Typography style={{textAlign:"center" , marginTop:"10px"}}> Users vs. Timeline</Typography>
   </Box>
   </Box>
  </Box>

  

);

export default Analytics;
