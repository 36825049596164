import {
  blackColor,
  hexToRgb,
} from "assets/jss/material-dashboard-react.js";

const platformSelector = {
  card: {
    Width: "600px",
    background: "rgba(255, 255, 255, 0.6)",
    boxShadow: "0px 8px 20px rgba(63, 39, 102, 0.12)",
    backdropFilter: "blur(20px)",
    borderRadius: "24px",
    maxheight: "100%",
    maxWidth: "83%",
  },
  cardHeader: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "25px",
    color: "#141D46",
  },
  platformCard: {
    display: "flex",
    flexDirection: "column",
    padding: "1vh 1vw",
    gap: "8px",
    background: "#FFFFFF",
    border: "1px solid rgba(199, 199, 199, 0.3)",
    backdropFilter: "blur(20px)",
    borderRadius: "12px",
    maxWidth: "100%",
    maxHeight: "100%",
    width: "8vw",
    height: "96px",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  selectPlatformCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1vh 1vw",
    gap: "8px",
    background:
      "linear-gradient(#F7FCFF, #F7FCFF) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)  border-box",
    border: " 1px solid transparent",
    backdropFilter: "blur(10px)",
    borderRadius: "12px",
    maxWidth: "100%",
    maxHeight: "100%",
    width: "8vw",
    height: "96px",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  selectCardTypo: {
    height: "16px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#0192D4",
    flex: "none",
    order: "1",
    flexGrow: "0",
  },
  cardTypography: {
    height: "16px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#424242",
    flex: "none",
    order: "1",
    flexGrow: "0",
  },
  validationText: {
    fontSize: "12px",
    color: "#FF2727",
  },
};

export default platformSelector;
