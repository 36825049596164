import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// core component(Layout)
import Admin from "layouts/Admin.js";
import Signin from "screens/signin/views/signin";

import { store, persistor } from "store/store";

import "./axios/interceptors";

import "assets/css/material-dashboard-react.css?v=1.10.0";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" component={Admin} />
          <Route path="/signin" component={Signin} />
       <Redirect to="/admin" /> 
        </Switch>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
