import React from "react";
import VivreAutocomplete from '../Autocomplete/Autocomplete'
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  Button,
  Paper,
  Switch,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { calculateImageRatio } from "../../utils/imageRatioCalculate";
import classNames from "classnames";

const imageShapesForRatio1x1 = [{ name: "Square" }, { name: "Circle" },{name:"Diamond"},{name:"Hexagon"}];
const imageShapesForRatio16x9 = [{ name: "Rectangle" },{ name: "Rounded Rectangle" }, { name: "Oval" },{name:"Hexagon"}];
const imageShapesForRatio9x16 = [{ name: "Rectangle" },{ name: "Rounded Rectangle" }, { name: "Oval" }];
const imageShapesForRatio2x3 = [{ name: "Rectangle" },{ name: "Rounded Rectangle" }, { name: "Oval" }];

const AspectRatioShape = ({
  classes,
  imageType,
  setImageType,
  bannerSelected,
  imageRatio,
  widgetCommonDetails,
  setWidgetCommonDetails,
  openMandatoryWarn,
  setOpenMandatoryWarn,
}) => {
  const [imageRatioCalculation, setImageRatioCalculation] = React.useState();
  const [imageShape,setImageShape]=React.useState()

  //Set image ratio calculation
  React.useEffect(() => {
   
    setImageRatioCalculation(calculateImageRatio(imageRatio))


//imgRatio=projectAspectRatio[bannerSelected] */
  }, [imageType, imageRatioCalculation,imageRatio]);

React.useEffect(()=>{},[imageShape])
React.useEffect((value,index)=>{
  if(imageRatioCalculation === 1){
    if(imageType?.imageType==="SQR"){
setImageShape("Square")

    }
    else if(imageType?.imageType==="CIR"){
setImageShape("Circle")
    }
    else if(imageType?.imageType==="DMD"){
      setImageShape("Diamond")
    }
    else if(imageType?.imageType==="HEX"){
      setImageShape("Hexagon")
    }
  }
  else{
if(imageType?.imageType==="LDS"||imageType.imageType==="PR2"){
  setImageShape("Rectangle")

}
if(imageType?.imageType==="RDLDS"||imageType.imageType==="RDPR2"){
  setImageShape("Rounded Rectangle")
}
if(imageType.imageType==="HEXLDS"){
  setImageShape("Hexagon")
}
else if(imageType?.imageType==="OVLLDS"||imageType.imageType==="OVPR2"){
  setImageShape("Oval")
}

}},[imageType?.imageType,imageRatioCalculation])

  //Set the Image Shape Code
  const getBannerShape = (event, value) => {
    let imageRatioCalculation = calculateImageRatio(imageRatio);
    if (imageRatioCalculation > 1) {
      if (value?.name === "Oval") {
     setImageShape("Oval",)
        setImageType({ ...imageType, imageType: "OVLLDS" });
      //  setWidgetCommonDetails({ ...widgetCommonDetails, imageType: "OVLND" });
      } else if (value?.name === "Rectangle") {
        setImageShape("Rectangle")
        setImageType({ ...imageType, imageType: "LDS" });
       // setWidgetCommonDetails({ ...widgetCommonDetails, imageType: "LND" });
      }else if (value?.name === "Rounded Rectangle") {
        setImageShape("Rounded Rectangle")
        setImageType({ ...imageType, imageType: "RDLDS" });
       // setWidgetCommonDetails({ ...widgetCommonDetails, imageType: "LND" });
      }
      else if (value?.name === "Hexagon") {
        setImageShape("Hexagon")
        setImageType({ ...imageType, imageType: "HEXLDS" });
      }
    } else if (imageRatioCalculation < 1) {
      if (value?.name === "Oval") {
        setImageShape("Oval")

        setImageType({ ...imageType, imageType: "OVPR2" });
      //  setWidgetCommonDetails({ ...widgetCommonDetails, imageType: "OVPR2" });
      } else if (value?.name === "Rectangle") {
        setImageShape("Rectangle")

        setImageType({ ...imageType, imageType: "PR2" });
      //  setWidgetCommonDetails({ ...widgetCommonDetails, imageType: "PR2" });
      }else if (value?.name === "Rounded Rectangle") {
        setImageShape("Rounded Rectangle")
        setImageType({ ...imageType, imageType: "RDPR2" });
      }
    } else if (imageRatioCalculation === 1) {
      if (value?.name === "Square") {
        setImageShape("Square")

        setImageType({ ...imageType, imageType: "SQR" });
     //   setWidgetCommonDetails({ ...widgetCommonDetails, imageType: "SQR" });
      } else if (value?.name === "Circle") {
        setImageShape("Circle")
        setImageType({ ...imageType, imageType: "CIR" });
  //      setWidgetCommonDetails({ ...widgetCommonDetails, imageType: "CIR" });
      }
      else if (value?.name === "Diamond") {
        setImageShape("Diamond")
        setImageType({ ...imageType, imageType: "DMD" });
      }
    }
  };
const getImageShapeByRatio = (ratio) => {
  console.log("🚀 ~ getImageShapeByRatio ~ ratio:", ratio)
    switch (ratio){
      case "1.00":
        return imageShapesForRatio1x1
      case "1.78":
        return imageShapesForRatio16x9
      case "0.56":
        return imageShapesForRatio9x16
      case "0.67":
        return imageShapesForRatio2x3
    }
} 
  return (
    <div>
      <div className={classes.widgetDetailContainer}>
        <Box className={classes.validateBox}>
          {/* <Autocomplete
            disabled={imageRatio == null}
            options={
              imageRatioCalculation === 1
                ? imageShapesForRatioOne
                : imageRatioForRatioLessAndMore
            }
            
            getOptionLabel={(option) => option.name}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                size="medium"
                className={classes.textField}
                {...params}
                placeholder="Select Banner Layout Shape *"
                variant="outlined"
                label={imageShape}
               
              />
            )}
            onChange={getBannerShape}
          />  */}
         
          <Box sx={{py:2}}>
          
      <VivreAutocomplete  
             disabled={imageRatio == null}
             defaultValue = {imageShape}
      options={
        getImageShapeByRatio(Number(imageRatioCalculation).toFixed(2))
              }  
      optionListStyle = {{width:"412px"}}
      getOptionLabel={(option) => option.name}           
      renderInput={(props) => {
              return <TextField
   
                {...props}
                size="medium"
                className={classes.textField}
                placeholder="Select Banner Layout Shape *"
                variant="outlined"
                label={"Banner Shape"}
              />
      }}  
            onChange={getBannerShape}
        />
        
          {imageRatio == null ? (
          <Typography style={{ color: "red", fontSize: "10px" ,marginTop:"10px"}}>
            Note: **First Select Banner Layout to select shape
          </Typography>
        ) : null}
    </Box>       
        </Box>
      </div>
      {openMandatoryWarn ? (
        imageType.imageType ? null : (
          <span className={classes.validationText}>* Mandatory Field</span>
        )
      ) : null}
    </div>
  );
};

export default AspectRatioShape;
