import React from "react";

//Material ui component
import Modals from "components/Modal/Modals";

//Material ui component
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  Button,
  Paper,
  Switch,
  OutlinedInput,
  InputBase,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "assets/img/icons/svg/CloseIcon.svg";


const previewImage = (props, ratio) => {
  if (ratio === "1x1") {
    return (
      <>
        {" "}
        <img
          style={{ width: "270px", height: "270px" ,marginTop:'10px'}}
          src={props?.previewDetails?.blackoutContent["1x1"]}
        />
      </>
    );
  } else if (ratio === "16x9") {
    return (
      <>
        {" "}
        <img
          style={{ width: "480px", height: "270px" ,marginTop:'10px'}}
          src={props?.previewDetails?.blackoutContent["16x9"]}
        />
      </>
    );
  } else if (ratio === "3x2") {
    return (
      <>
        {" "}
        <img
          style={{ width: "405px", height: "270px",marginTop:'10px' }}
          src={props?.previewDetails?.blackoutContent["3x2"]}
        />
      </>
    );
  }
};

const BlackoutContentPreview = (props) => {
  const [selectRatio, setSelectRatio] = React.useState();

  React.useEffect(() => {
    props?.previewDetails?._id
      ? setSelectRatio(Object.keys(props?.previewDetails?.blackoutContent)[0])
      : null;
  }, [props.previewDetails]);

  //Select content Ratio func
  const selectContentRatio = (event, value) => {
    setSelectRatio(value);
  };
  return (
    <Modals
      open={props.open}
      setModal={props.setPreviewOpen}
      setAssetIdBrowse={() => {}}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "800px",
          height: "500px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
         //justifyContent: "center",
          borderRadius: "20px",
          paddingTop:'30px'
        }}
      >
        <div style={{
          width: "100%", display: "flex",
          display: "flex",
          justifyContent: "flex-end",
          top: "35px",
          position: "absolute",
          left: "-24%",
        }}
          onClick={() => props.setPreviewOpen(false)}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              border: '1px solid',
              height: "32px",
              width: "32px",
              cursor: 'pointer'
            }}
          >
            <img style={{ width: "15px", height: "15px" }} src={CloseIcon} />
          </span>
        </div>
          <Typography variant="h4" style={{marginBottom:'20px'}}>Blackout Content Preview</Typography>
          

        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Typography style={{marginBottom:'10px'}}>Select Blackout Content Ratio</Typography>{" "}
          <Autocomplete
            defaultValue={
              props?.previewDetails?._id
                ? Object.keys(props?.previewDetails?.blackoutContent)[0]
                : null
            }
            // disabled={imageRatio == null}
            options={
              props?.previewDetails?._id
                ? Object.keys(props?.previewDetails?.blackoutContent)
                : null
            }
            getOptionLabel={(option) => option}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                style={{ width: "200px" }}
                size="medium"
                {...params}
                placeholder="Select Platform"
                variant="outlined"
              />
            )}
            onChange={selectContentRatio}
          />
        </Box>
        <Box>
         { /*<Typography style={{marginTop:'10px'}}>{selectRatio} Blackout Image</Typography>*/}
          {props?.previewDetails._id ? previewImage(props, selectRatio) : null}
        </Box>
      </div>
    </Modals>
  );
};

export default BlackoutContentPreview;
