import { combineReducers } from "redux";
import { BlackoutScheduleReducer } from "screens/BlackoutSchedule/reducer";
import { ContentExplorerReducer } from "screens/ContactExplorer/reducers";
import { dictReducer } from "screens/MessageDict/reducer";
import { screenVisualizationReducer } from "screens/PlatformManager/reducers";
import createScreenReducer from "screens/PlatformManager/reducers/createScreenReducer";
import getPlatformReducer from "screens/PlatformManager/reducers/getplatfromReducer";
import getScreenReducer from "screens/PlatformManager/reducers/getScreenReducer";
import getWidgetDetailsReducer from "screens/PlatformManager/reducers/getWidgetDetailsReducer";
import getWidgetReducer from "screens/PlatformManager/reducers/getWidgetReducer";
import { ProjectReducer } from "screens/Projects/reducers";
import { PushNotificationReducer } from "screens/PushNotification/reducers";
import loginReducer from "screens/signin/reducers/loginReducers";
import usersReducers from "screens/Users/reducers";
import { PlayListReducer } from "screens/WidgetManager/reducers";

const rootReducers = combineReducers({
  loginReducer,
  screenVisualizationReducer,
  ProjectReducer,
  PlayListReducer,
  getPlatformReducer,
  getScreenReducer,
  getWidgetReducer,
  getWidgetDetailsReducer,
  createScreenReducer,
  ContentExplorerReducer,
  usersReducers,
  BlackoutScheduleReducer,
  dictReducer,
  PushNotificationReducer
});
export default (state, action) => rootReducers(action.type === "LOGOUT_REQUEST" ? undefined : state, action);
