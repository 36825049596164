// core components/screens for Admin layout
import VisualizationScreen from "screens/PlatformManager/views/visualization/VisualizationScreen";
import AddProjectstepper from "screens/Projects/views/addProjectStepper";
import EditProjects from "screens/Projects/views/Edit/EditProject";
import Projects from "screens/Projects/views/Projects";
import PublishScreen from "screens/PublishScreen/views/PublishScreen";
import AddUser from "screens/Users/views/AddUser";
import EditUser from "screens/Users/views/EditUser";
import Users from "screens/Users/views/Users";
import Analytics from "screens/Analytics/views/Analytics";
import PushNotification from "screens/PushNotification/views/PushNotification";
import Help from "screens/Help/views/Help";
import AddWidgetManager from "screens/WidgetManager/views/AddWidgetManager";
import contactDarkIcon from "./assets/img/navbarLogo/ContentIcon_dark.svg";
import ContentIcon from "./assets/img/navbarLogo/ContentIcon.svg";
import dashBoardDark from "./assets/img/navbarLogo/dashboardDarkIcon.png";
import dashBoard from "./assets/img/navbarLogo/dashboardIcon.png";
import platformDarkIcon from "./assets/img/navbarLogo/platformDarkIcon.png";
import platformIcon from "./assets/img/navbarLogo/platformIcon.png";
import projectDarkIcon from "./assets/img/navbarLogo/projectDarkIcon.svg";
import projectIcon from "./assets/img/navbarLogo/Projects.svg";
import publishDarkIcon from "./assets/img/navbarLogo/publishDarkIcon.png";
import publishIcon from "./assets/img/navbarLogo/publishIcon.png";
import scheduleIcon from "./assets/img/navbarLogo/scheduleIcon.svg";
import scheduleDarkIcon from "./assets/img/navbarLogo/ScheduleIcon_dark.svg";
import MessageDictIcon from "./assets/img/navbarLogo/MessageDictIcon.svg";
import MessageDictDarkIcon from "./assets/img/navbarLogo/MessageDictIcon_dark.svg";
import WidgetManagerDarkIcon from "./assets/img/navbarLogo/WidgetManagerIcon_dark.svg";
import WidgetManagerIcon from "./assets/img/navbarLogo/WidgetManagerIcon.svg";
import UserIcon from "./assets/img/navbarLogo/UserIcon.svg";
import UserIconDark from "./assets/img/navbarLogo/UserIcon_dark.svg" 
import AnalyticsIcon from "./assets/img/navbarLogo/AnalyticsIcon.svg";
import PushNotificationIcon from "./assets/img/navbarLogo/PushNotificationIcon.svg" 
import PushNotificationIconDark from "./assets/img/navbarLogo/PushNotificationIcon_dark.svg";
import AnalyticsIconDark from "./assets/img/navbarLogo/AnalyticsIcon_dark.svg";
import helpIcon from "./assets/img/navbarLogo/helpIcon.svg";
import helpIconDark from "./assets/img/navbarLogo/helpIcon_dark.svg";

import PrivacyIcon from "assets/img/headNavLogo/PrivacyPolicy.svg"
import TearmsandConditionsIcon from "assets/img/headNavLogo/terms&conditions.svg"
import PrivacyDarkIcon from "assets/img/headNavLogo/PrivacyPolicyDark.svg"
import TearmsandConditionsDarkIcon from "assets/img/headNavLogo/t&cDark.svg"
//BlackoutSchedule Component Import
import BlackoutSchedule from "./screens/BlackoutSchedule/views";
//Add Blackout Screen
import ScheduleBlackout from "./screens/BlackoutSchedule/views/scheduleBlackout";
import ContactExplorer from "./screens/ContactExplorer/views/ContactExplorer";
//App Screens
import AppScreens from "./screens/PlatformManager/views/appScreens";
import PlatformManager from "./screens/PlatformManager/views/PlatformManger";
import WidgetManager from "./screens/WidgetManager/views/_WidgetManager";
import MessageDict from "./screens/MessageDict/views";
import PrivacyPolicy from "./screens/PrivacyPolicy/views/privacypolicy";
import TearmsAndCondition from "./screens/TearmsAndConditions/views/TearmsAndCondition";
import EditPushNotification  from "screens/PushNotification/views/EditPushNotification";
import ScreenTheme from "screens/PlatformManager/views/visualization/Screen_Theme";



const dashboardRoutes = [
  {
    path: "/projects",
    name: "Projects",
    darkIcon: projectDarkIcon,
    icon: projectIcon,
    component: Projects,
    layout: "/admin",
  },

  {
    path: "/widgetmanager",
    name: "Widget Manager",
    icon: WidgetManagerIcon,
    darkIcon: WidgetManagerDarkIcon,
    component: WidgetManager,
    layout: "/admin",
  },
  {
    path: "/contactexplorer",
    name: "Content Explorer",
    icon: ContentIcon,
    darkIcon: contactDarkIcon,
    component: ContactExplorer,
    layout: "/admin",
  },

  // {
  //   path: "/settings",
  //   name: "Settings",
  //   icon: settingIcon,
  //   darkIcon: settingDarkIcon,
  //   component: Settings,
  //   layout: "/admin",
  // },

  {
    path: "/blackoutschedule",
    name: "Blackout Schedule",
    icon: scheduleIcon,
    darkIcon: scheduleDarkIcon,
    component: BlackoutSchedule,
    layout: "/admin",
  },

  {
    path: "/messagedict",
    name: "Message Dictionary",
    icon: MessageDictIcon,
    darkIcon: MessageDictDarkIcon,
    component: MessageDict,
    layout: "/admin",
  },
  {
    path: "/pushnotification",
    name: "PushNotification",
    darkIcon: PushNotificationIconDark,
    icon:PushNotificationIcon,
    component: PushNotification,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Admin",
    icon: UserIcon,
    darkIcon: UserIconDark,
    component: Users,
    layout: "/admin",
  },
  {
    path: "/analytics",
    name: "Analytics",
    darkIcon: AnalyticsIconDark,
    icon:AnalyticsIcon,
    component: Analytics,
    layout: "/admin",
  },
  {
    path: "/help",
    name: "Help",
    darkIcon: helpIconDark,
    icon: helpIcon,
    component: Help,
    layout: "/admin",
  },
  {
    path: "/tearmsandconditions",
    name: "Terms & Conditions",
    icon: TearmsandConditionsIcon,
    darkIcon: TearmsandConditionsDarkIcon,
    component: TearmsAndCondition,
    layout: "/admin",
  },
  {
    path: "/privacypolicy",
    name: "Privacy Policy",
    icon: PrivacyIcon,
    darkIcon: PrivacyDarkIcon,
    component: PrivacyPolicy,
    layout: "/admin",
  },
  {
    path: "/projectsplateformmanager",
    name: "Platform Manager",
    darkIcon: platformDarkIcon,
    icon: platformIcon, // icon: "content_paste",
    component: PlatformManager,
    layout: "/admin",
  },
  {
    path: "/publishscreen",
    name: "Publish Screen",
    icon: publishIcon,
    darkIcon: publishDarkIcon,
    component: PublishScreen,
    layout: "/admin",
  },
  {
    path: "/projects/addprojects",
    name: "Add Project",
    icon: "",
    darkIcon: "",
    component: AddProjectstepper,
    layout: "/admin",
  },
  {
    path: "/projects/edit",
    name: "Edit Project",
    icon: "",
    darkIcon: "",
    component: EditProjects,
    layout: "/admin",
  },
  {
    path: "/projectsplateformmanager/screens",
    name: "Screen",
    icon: "",
    darkIcon: "",
    component: VisualizationScreen,
    layout: "/admin",
  },
  {
    path: "/projectsplateformmanager/screens/theme",
    name: "ScreenTheme",
    icon: "",
    darkIcon: "",
    component: ScreenTheme,
    layout: "/admin",
  },
  {
    path: "/projectsplateformmanager/appscreens",
    name: "App Screens",
    icon: "",
    darkIcon: "",
    component: AppScreens,
    layout: "/admin",
  },
  {
    path: "/widgetmanager/add",
    name: "Add Widget Manager",
    icon: "",
    darkIcon: "",
    component: AddWidgetManager,
    layout: "/admin",
  },
  {
    path: "/widgetmanager/edit",
    name: "Add Widget Manager",
    icon: "",
    darkIcon: "",
    component: AddWidgetManager,
    layout: "/admin",
  },
  {
    path: "/pushnotification/edit",
    name: "Push Notification Edit",
    darkIcon: "",
    icon:"",
    component: EditPushNotification,
    layout: "/admin",
  },
  {
    path: "/users/adduser",
    name: "Add User",
    icon: "",
    darkIcon: "",
    component: AddUser,
    layout: "/admin",
  },
  {
    path: "/users/edituser",
    name: "Edit User",
    icon: "",
    darkIcon: "",
    component: EditUser,
    layout: "/admin",
  },
  {
    path: "/blackoutschedule/addblackout",
    name: "Blackout Schedule",
    icon: "",
    darkIcon: "",
    // component:BlackoutSchedule,
    component: ScheduleBlackout,
    layout: "/admin",
  },
];

export default dashboardRoutes;
