import React from "react";
import { AntSwitch } from "components/Switch/AntSwitch";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  Button,
  Paper,
  Switch,
} from "@material-ui/core";

import addHorizontalImage from "assets/img/addWidgetManager/addHorizontalRailImg.svg";
import { produceWithPatches } from "@reduxjs/toolkit/node_modules/immer";

import styles from "assets/jss/material-dashboard-react/views/widgetMangerStyle";

const useStyles = makeStyles(styles);

const selRailCardHorizonRail = ["Small", "Medium", "Large"];
const HorizontalRailsType = (props) => {
  const [selectedCardType, setSelectCardType] = React.useState(0);
  const [selectedCardSize, setSelectCardSize] = React.useState(0);
  const [selectedLayout, setSelectedLayout] = React.useState(0);

  const classes = useStyles();

  const setTitleName = (event) => {
    props.setHorRailWidgetDataType({
      ...props.horRailWidgetDataType,
      title: event.target.value,
    });
  };
  React.useEffect(()=>{


///Rail card type
if(props?.horRailWidgetDataType?.railCardType==='IMAGE'){
  setSelectCardType(0);
}
else if(props?.horRailWidgetDataType?.railCardType==='IMAGE_TITLE'){ setSelectCardType(1);}
else if(props?.horRailWidgetDataType?.railCardType==='IMAGE_TITLE_DESC'){setSelectCardType(2);}
else if(props?.horRailWidgetDataType?.railCardType==='CUS'){setSelectCardType(3);}

///Selecting rail card size
if(props?.horRailWidgetDataType?.railCardSize==="SMALL"){
  setSelectCardSize(0)
}
else if(props?.horRailWidgetDataType?.railCardSize==="NORMAL"){
  setSelectCardSize(1)
}
else if(props?.horRailWidgetDataType?.railCardSize==="LARGE"){
  setSelectCardSize(2)
}


//Listing layout

if(props?.horRailWidgetDataType?.listingLayout==="GRD"){
  setSelectedLayout(1)
}
else{
  setSelectedLayout(0)
}


    console.log("Prodpscheck karo",props.horRailWidgetDataType);
  },[])

  const getRailCardType = (type) => {
    if (type === "notitle") {
      setSelectCardType(0);
      props.setHorRailWidgetDataType({
        ...props.horRailWidgetDataType,
        railCardType: "IMAGE",
      });
    } else if (type === "title") {
      setSelectCardType(1);
      props.setHorRailWidgetDataType({
        ...props.horRailWidgetDataType,
        railCardType: "IMAGE_TITLE",
      });
    } else if (type === "titledes") {
      setSelectCardType(2);
      props.setHorRailWidgetDataType({
        ...props.horRailWidgetDataType,
        railCardType: "IMAGE_TITLE_DESC",
      });
    } else if(type === "custom"){
      setSelectCardType(3);
      props.setHorRailWidgetDataType({
        ...props.horRailWidgetDataType,
        railCardType: "CUS",
      });
    }
  };
  const selectRailCard = (type, index) => {
    setSelectCardSize(index);
    if (type === "Small") {
      props.setHorRailWidgetDataType({
        ...props.horRailWidgetDataType,
        railCardSize: "SMALL",
      });
    } else if (type === "Medium") {
      props.setHorRailWidgetDataType({
        ...props.horRailWidgetDataType,
        railCardSize: "NORMAL",
      });
    } else if (type === "Large") {
      props.setHorRailWidgetDataType({
        ...props.horRailWidgetDataType,
        railCardSize: "LARGE",
      });
    }
  };
  const selectLayout = (type) => {
    if (type === "List") {
      setSelectedLayout(0);
      props.setHorRailWidgetDataType({
        ...props.horRailWidgetDataType,
        listingLayout: "LST",
      });
    } else if (type === "Grid") {
      setSelectedLayout(1);
      props.setHorRailWidgetDataType({
        ...props.horRailWidgetDataType,
        listingLayout: "GRD",
      });
    }
  };

  const shoreMoreButton = (event) => {
    props.setHorRailWidgetDataType({
      ...props.horRailWidgetDataType,
      showMoreButton: event.target.checked,
    });
   /* if(!event.target.checked){props.setHorRailWidgetDataType({
      ...props.horRailWidgetDataType,
      pageSize: "",
    });} */

/*    if(props?.routedata?.pathname==="/admin/widgetmanager/edit"){
      if(!event.target.checked&&props.horRailWidgetDataType.pageSize){
        props.setHorRailWidgetDataType({
          ...props.horRailWidgetDataType,
          pageSize: null,
        })
      }
      else{ props.setHorRailWidgetDataType({
        ...props.horRailWidgetDataType,
        pageSize:props.routedata?.state?.widgetDetails?.item?.pageSize,
      })}
    } */
  };

  const setNumberOfItemInRail = (event) => {
    const number = parseInt(event.target.value);
    props.setHorRailWidgetDataType({
      ...props.horRailWidgetDataType,
      listingLayoutContentSize : number,
    });
  };

  const setSizeOfPage = (event) => {
    const number = parseInt(event.target.value);
    props.setHorRailWidgetDataType({
      ...props.horRailWidgetDataType,
      pageSize: number,
    });
  };
  const allowSorting = (event) => {};
  const enableMultilingualTitle = (event) => {
    props.setHorRailWidgetDataType({
      ...props.horRailWidgetDataType,
      enableMultilingualTitle: event.target.checked,
    });
  };

  const setTitleNameEnUs = (event) => {};

  var selectedGridLayout;
  var selectedListLayout;
  var selectedCardTitle;
  var selectedCardNoTitle;
  var selectedCardDesTitle;
  var selectedCardCustom
  if (selectedCardType === 0) {
    selectedCardNoTitle = classNames({
      ["  " + classes.selectedType]: true,
    });
  } else if (selectedCardType === 1) {
    selectedCardTitle = classNames({
      ["  " + classes.selectedType]: true,
    });
  } else if (selectedCardType === 2) {
    selectedCardDesTitle = classNames({
      ["  " + classes.selectedType]: true,
    });
  }else if (selectedCardType === 3) {
    selectedCardCustom = classNames({
      ["  " + classes.selectedType]: true,
    });
  }

  if (selectedLayout === 0) {
    selectedListLayout = classNames({
      ["  " + classes.selectedType]: true,
    });
  } else if (selectedLayout === 1) {
    selectedGridLayout = classNames({
      ["  " + classes.selectedType]: true,
    });
  }

  return (
    <div>
      {" "}
      <Typography style={{}}>Title (Optional)</Typography>
      <div className={classes.widgetDetailContainer}>
        {" "}
        <TextField
        value={props.horRailWidgetDataType.title}
          variant="outlined"
          placeholder="Enter Title"
          size="medium"
          className={classes.textField}
          style={{}}
          onChange={setTitleName}
        />
      </div>
      <div>
        <Typography>Select Rail Card Type</Typography>
        <div
          className={classes.widgetDetailContainer}
          style={{
            width: "600px",
          }}
        >
          {" "}
          <div
            className={classNames(
              classes.cardTypeContainer,
              selectedCardNoTitle
            )}
            style={{}}
            onClick={() => getRailCardType("notitle")}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#D8E3E8",
                height: "64px",
                borderRadius: "8px",
                margin: "5px",
                boxSizing: "border-box",
              }}
            >
              <img
                src={addHorizontalImage}
                style={{ height: "18px", width: "21px" }}
              />
              <Typography>Image</Typography>
            </div>
          </div>
          <div
            className={classNames(classes.cardTypeContainer, selectedCardTitle)}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            onClick={() => getRailCardType("title")}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#D8E3E8",
                height: "64px",
                borderRadius: "8px",
                margin: "5px",
                boxSizing: "border-box",
              }}
            >
              <img
                src={addHorizontalImage}
                style={{ height: "18px", width: "21px" }}
              />
              <Typography>Image</Typography>
            </div>
            <Typography>Title</Typography>
          </div>
          <div
            className={classNames(
              classes.cardTypeContainer,
              selectedCardDesTitle
            )}
            style={{}}
            onClick={() => getRailCardType("titledes")}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#D8E3E8",
                height: "64px",
                borderRadius: "8px",
                margin: "5px",
                boxSizing: "border-box",
              }}
            >
              <img
                src={addHorizontalImage}
                style={{ height: "18px", width: "21px" }}
              />
              <Typography>Image</Typography>
            </div>
            <Typography>Title</Typography>
            <Typography style={{ fontSize: "12px" }}>Description</Typography>
          </div>
          <div
            className={classNames(
              classes.cardTypeContainer,
              selectedCardCustom
            )}
            style={{}}
            onClick={() => getRailCardType("custom")}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#D8E3E8",
                height: "64px",
                borderRadius: "8px",
                margin: "5px",
                boxSizing: "border-box",
              }}
            >
              <img
                src={addHorizontalImage}
                style={{ height: "18px", width: "21px" }}
              />
              <Typography>Image</Typography>
            </div>
            <Typography>Custom</Typography>
          </div>
        </div>
      </div>
      <div>
        <Typography>Select Rail Card size</Typography>
        <div
          className={classes.widgetDetailContainer}
          style={{
            width: "450px",
          }}
        >
          {selRailCardHorizonRail.map((value, index) => {
            var selectedCardSizeClass;
            if (index === selectedCardSize) {
              selectedCardSizeClass = classNames({
                ["  " + classes.selectedType]: true,
              });
            }
            return (
              <span
                style={{}}
                className={classNames(
                  classes.cardSizeContainer,
                  selectedCardSizeClass
                )}
                onClick={() => selectRailCard(value, index)}
              >
                {value}
              </span>
            );
          })}
        </div>
      </div>
      <div>
        <Typography>Select Layout</Typography>
        <div
          className={classes.widgetDetailContainer}
          style={{
            width: "350px",
          }}
        >
          <span
            className={classNames(classes.cardLayout, selectedListLayout)}
            style={{}}
            onClick={() => selectLayout("List")}
          >
            List view
          </span>
          <span
            className={classNames(classes.cardLayout, selectedGridLayout)}
            style={{}}
            onClick={() => selectLayout("Grid")}
          >
            Grid View
          </span>
        </div>
      </div>
      <div>
        
        <Typography style={{}}>Number Of Item in Rail</Typography>
        <div className={classes.widgetDetailContainer}>
        <Box className={classes.validateBox} >       
          {" "}
          <TextField
          //disabled={! props.horRailWidgetDataType.showMoreButton}
          value={props?.horRailWidgetDataType?.listingLayoutContentSize?props?.horRailWidgetDataType?.listingLayoutContentSize:""}
            placeholder="Number Of Item in Rail *"
            size="medium"
            className={classes.textField}
            variant="outlined"
            style={{}}
           onChange={setNumberOfItemInRail}
          />
           {props.openMandatoryWarn ? (
            
                props.horRailWidgetDataType.listingLayoutContentSize? null : (
                  <span className={classes.validationText}>* Mandatory Field</span>
                )
              ) : null
           }
        
        </Box>
        </div>

      </div>
      <div
        className={classes.widgetDetailContainer}
        style={{
          width: "300px",
        }}
      >
        
        <Typography>Show More Buttons/Texts</Typography>
        <AntSwitch defaultChecked={props?.horRailWidgetDataType?.showMoreButton?props?.horRailWidgetDataType?.showMoreButton:false} name="checkedC" onChange={shoreMoreButton} />
      </div>
      <div>
        
        <Typography style={{}}>Size Of Page in more Section</Typography>
        <div className={classes.widgetDetailContainer}>
        <Box className={classes.validateBox} >       
          {" "}
          <TextField
          disabled={! props.horRailWidgetDataType.showMoreButton}
          value={props?.horRailWidgetDataType?.showMoreButton?props?.horRailWidgetDataType?.pageSize:""}
            placeholder="Size Of Page In the more Section"
            size="medium"
            className={classes.textField}
            variant="outlined"
            style={{}}
            onChange={setSizeOfPage}
          />
           {props.openMandatoryWarn ? (
             props.horRailWidgetDataType.showMoreButton ? (
                props.horRailWidgetDataType.pageSize? null : (
                  <span className={classes.validationText}>* Mandatory Field</span>
                )
              ) : null
            ) : null}
        
        </Box>
        </div>

      </div>
      <div
        className={classes.widgetDetailContainer}
        style={{
          width: "300px",
        }}
      >
        <Typography>Allow Sorting</Typography>
        <AntSwitch name="checkedC" onChange={allowSorting} />
      </div>
      <div>
        {" "}
        <Typography style={{}}>Filter</Typography>
        <div className={classes.widgetDetailContainer}>
          {" "}
          <TextField
            variant="outlined"
            placeholder="Filter"
            size="medium"
            className={classes.textField}
            style={{}}
          />
        </div>
      </div>
      <div>
        {" "}
        <Typography style={{}}>Select playList</Typography>
        <Box className={classes.validateBox} >
        <Box className={classes.playListBrowseContainer} style={{}}>
          <Box> {props.playListId ? `${props.playListId}` + `${" "}` : "PlayList*"} {props.playlistName ? `${props.playlistName}` : ""}</Box>
          <Box
            className={classes.playlistBrowseButton}
            onClick={() => props.setOpenModal(!props.openModal)}
          >
            Browse
          </Box>
        </Box>
        {props.openMandatoryWarn ? (
              props.playListId? null : (
                <span className={classes.validationText}>* Mandatory Field</span>
              )
            ) : null}
        </Box>
      </div>
      {/*    <div
        className={classes.widgetDetailContainer}
        style={{
          width: "300px",
        }}
      >
        <Typography>Enable Multilingual Title</Typography>
        <AntSwitch name="checkedC" onChange={enableMultilingualTitle} />
      </div>
      <div>
        {" "}
        <Typography style={{}}>Title Name (En-Us)</Typography>
        <div className={classes.widgetDetailContainer}>
          {" "}
          <TextField
            variant="outlined"
            placeholder="Enter Title Name (En-Us)"
            size="medium"
            className={classes.textField}
            style={{}}
            onChange={setTitleNameEnUs}
          />
        </div>
      </div> */}
    </div>
  );
};

export default HorizontalRailsType;
