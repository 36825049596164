import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import { Avatar, Typography } from "@material-ui/core";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import avatar from "../../assets/img/headNavLogo/avatar.png";
import downArrow from "../../assets/img/headNavLogo/down.png";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import Dialog from "components/Dialog/Dialog";
import LogoutIcon from "assets/img/icons/svg/logout.svg";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks(props) {
  const history = useHistory();

  const classes = useStyles();
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const [openLogout, setOpenLogout] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  //Logout Function for opening dialog
  const openDialog = () => {
    setOpenLogout(true);
  };

  //Logout Function for log out user
  const logOutUser = () => {
    dispatch({ type: "LOGOUT_REQUEST" });
    localStorage.clear();
    history.push("/signin");
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Dashboard"
        className={classes.buttonLink}
      >
        {/* <img className={classes.icons} src={avatar} /> */}
        <Avatar
          style={{
            width: "26px",
            height: "26px",
            border: "3px solid #FFF",
            marginRight: "8px",
          }}
        >
          <Typography
            style={{
              fontSize: "16px",
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: 600,
            }}
          >
            {props?.name?.charAt(0)}
          </Typography>
        </Avatar>
      </Button>
      <div className={classes.searchWrapper}>
        <Typography className={classes.userName}>{props.name}</Typography>
      </div>

      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <img src={downArrow} className={classes.icon} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={classNames({ [classes.popperClose]: !openProfile }) + " " + classes.popperNav}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    {/*   <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Settings
                    </MenuItem>
                    <Divider light /> */}
                    <MenuItem onClick={openDialog} className={classes.dropdownItem}>
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
                <Dialog
                  open={openLogout}
                  title={<span className={classes.dialog}>Are you sure you want to Logout?</span>}
                  button1="Cancel"
                  button2="Yes"
                  button1Event={() => setOpenLogout(false)}
                  button2Event={logOutUser}
                  icon={LogoutIcon}
                  margin="8px 8px 8px 16px"
                  width="36px"
                />
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
