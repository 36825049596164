import React from "react";
import { connect } from "react-redux";

//import history from react router
import { useHistory } from "react-router-dom";

//Typography component
import Primary from "../../../components/Typography/Primary";

//Material ui component
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  Button,
  Paper,
  Switch,
  OutlinedInput,
  InputBase,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Menuitem from "components/Menu/MenuItem";

//SVG Import
import SearchIcon from "../../../assets/img/icons/SearchIcon.svg";
import DropDown from "../../../assets/img/icons/dropDown.svg";
import BlackOutIcon from "assets/img/sideNavIcons/BlackoutSchedule.svg"

//CSS JSS file
import style from "../../../assets/jss/material-dashboard-react/views/blackoutScheduleStyle";

//Blackout Schedule Component
import BlackoutScheduleTable from "../../../components/Table/BlackoutScheduleTable";
import RemoveSchedulePopUp from "../../../components/blackoutScheduleComponents/RemoveSchedulePopup";
import BlackoutContentPreview from "../../../components/blackoutScheduleComponents/BlackoutContentPreview";

//Actions used in this compponent
import {
  getAllTheBlackoutsForAllChannelsAction,
  getAllLinearChannelAction,
  deleteBlackoutAction,
} from "../actions";

//Icons import
import Addicon from "../../../assets/img/icons/svg/Ic_add.svg";

//Custom component
import Snackbar from "components/Snackbar/CustomSnackbar";

const useStyle = makeStyles(style);

//Temparary array

//Temporary Array
const tableBody = [
  {
    channelName: "CHCH",
    startDateTime: "15/12/2022 11:00am",
    endDateTime: "15/12/2022 1:00pm",
    message: "Not Available for Web",
    content: {
      type: "image",
      cont:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
    },
  },
  {
    channelName: "CHCH",
    startDateTime: "15/12/2022 11:00am",
    endDateTime: "15/12/2022 1:00pm",
    message: "Not Available for Web",
    content: {
      type: "image",
      cont:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
    },
  },
  {
    channelName: "CHCH",
    startDateTime: "15/12/2022 11:00am",
    endDateTime: "15/12/2022 1:00pm",
    message: "Not Available for Web",
    content: {
      type: "image",
      cont:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
    },
  },
  {
    channelName: "CHCH",
    startDateTime: "15/12/2022 11:00am",
    endDateTime: "15/12/2022 1:00pm",
    message: "Not Available for Web",
    content: {
      type: "image",
      cont:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
    },
  },
  {
    channelName: "CHCH",
    startDateTime: "15/12/2022 11:00am",
    endDateTime: "15/12/2022 1:00pm",
    message: "Not Available for Web",
    content: {
      type: "image",
      cont:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
    },
  },
  {
    channelName: "CHCH",
    startDateTime: "15/12/2022 11:00am",
    endDateTime: "15/12/2022 1:00pm",
    message: "Not Available for Web",
    content: {
      type: "image",
      cont:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
    },
  },
];

//Parent Component for the Blackout Schedule
const BlackoutSchedule = (props) => {
  const classes = useStyle();
  const history = useHistory();

  const [filter, setFilter] = React.useState(false);
  const [menuItemChannelName, setMenuItemChannelName] = React.useState(["CHCH", "CHCH"]);
  const [searchSchedule, setSearchSchedule] = React.useState();
  const [deleteSchedueOpen, setDeleteScheduleOpen] = React.useState(false);
  const [deleteBlackoutDetails, setDeleteBlackoutDetails] = React.useState({
    p_id: "",
    blackoutDetails: {},
  });

  const [previewDetails, setPreviewDetails] = React.useState({});

  const [openPreview, setPreviewOpen] = React.useState(false);

  const [accessSnack, setAccessSnack] = React.useState(false);
  const [accessSnackMessage, setAccessSnackMessage] = React.useState("");
 const [searchedChannel,setSearchedChannel]=React.useState([])
 const [searchedContent,setSearchedContent]=React.useState()
  //Calling the action to get all the blackout for all channel
  React.useEffect(() => {
    props.getAllTheBlackoutsForAllChannelsAction(props?.projectDetails?.p_id);
  }, [props.projectDetails, props?.delete]);

  //Add Blackout Schedule function to open ScheduleBlackout Page
  const addBlackoutSchedule = () => {
    props.accessLevel !== "user"
      ? [
          props.getAllLinearChannelAction(props?.projectDetails?.p_id),
          history.push("/admin/blackoutschedule/addblackout"),
        ]
      : [setAccessSnackMessage("You do not have access to add blackouts."), setAccessSnack(true)];
  };

  //Delete Blackout Schedule
  const deleteSchedule = (scheduleDetails) => {
    props.accessLevel !== "user"
      ? [
          setDeleteBlackoutDetails({
            ...deleteBlackoutDetails,
            blackoutDetails: scheduleDetails,
            p_id: props?.projectDetails?.p_id,
          }),
          //props.deleteBlackoutAction(scheduleDetails,props?.projectDetails?.p_id)
          setDeleteScheduleOpen(true),
        ]
      : [
          setAccessSnackMessage("You do not have access to delete blackouts."),
          setAccessSnack(true),
        ];
  };

  //Search Linear CHannel
  const searchLinearChannel = (event) => {
    console.log("Value for search", event.target.value);
    const searchTerm = event.target.value.toLowerCase();
    setSearchedContent(searchTerm);
    let searchedChannel = props?.blackoutsForAllChannel.filter((channel) => {
      return channel.channelName.toLowerCase().includes(searchTerm);
    });
    console.log(searchedChannel, "searchedChannel");
    setSearchedChannel(searchedChannel);
  };
  

  console.log(props?.blackoutsForAllChannel,"props?.blackoutsForAllChannel")

  return (
    <div>
      <Snackbar
        open={accessSnack}
        severity={"warning"}
        message={accessSnackMessage}
        setOpen={() => setAccessSnack(false)}
      />
      <Grid container>
        <Grid item xs={6} md={6} lg={6}>
          <Box px={2} pb={2} display={"flex"}>
          <img src={BlackOutIcon} alt="Your Icon" style={{ width: '33px', height: '33px', marginRight: '8px', verticalAlign: 'middle' }} />
            <Primary>Blackout Schedules </Primary>
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Typography className={classes.heading}>Blackouts </Typography>

        {/* 
         <div> <LinearChannel rail={rail} />
             </div> */}
      </Box>
      <Box>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item xs={6} md={6} lg={6}>
            <Box display="flex">
              <Box className={classes.searchBoxLinearChannelBox}>
                <img className={classes.searchIcon} src={SearchIcon} alt="Search Icon" />
                <InputBase
                  placeholder="Search Linear Channel"
                  className={classes.blackoutScheduleViewTextField}
                  labelwidth={0}
                  onChange={searchLinearChannel}
                />
              </Box>
            </Box>
          </Grid>
          {/* <ClickAwayListener
            onClickAway={() => {
              //   handleClickAway("Published");
            }}
          >
            <div style={{ cursor: "pointer", marginLeft: "70px" }}>
              <Box className={classes.filterSelectChannelName}>
                Channel Name
                <img
                  className={classes.dropDownClassChannelCategory}
                  src={DropDown}
                  alt="dropDown Icon"
                />
              </Box>
              {filter ? (
                <div
                  style={{
                    width: "180px",
                    position: "absolute",
                    backgroundColor: "white",
                    width: "150px",
                    marginTop: "6px",
                    borderRadius: "10px",
                    zIndex: 9999,
                  }}
                >
                  {" "}
                  <Menuitem menues={menuItemChannelName} />
                </div>
              ) : null}
            </div>
          </ClickAwayListener> */}

          <Grid item xs={6} md={6} lg={6}>
            <Box display="flex" justifyContent="right" marginTop="0px">
              <Button className={classes.Button} onClick={addBlackoutSchedule}>
                <img src={Addicon} style={{ paddingRight: "0.75rem" }} />
                Add Blackout
              </Button>
            </Box>
          </Grid>
        </div>
        <div style={{ marginTop: "-15px" }}>
          <BlackoutScheduleTable
            tableHead={[
              "Channel Name",

              "Blackout Start Date/Time",
              "Blackout End Date/Time",
              "Blackout Message",
              "Delete ",
              "Preview",
            ]}
            tableData={searchedContent?searchedChannel.length>0?searchedChannel:null:props?.blackoutsForAllChannel}
            deleteSchedule={deleteSchedule}
            setPreviewOpen={setPreviewOpen}
            openPreview={openPreview}
            setPreviewDetails={setPreviewDetails}
          />
        </div>
      </Box>
      <RemoveSchedulePopUp
        open={deleteSchedueOpen}
        setDeleteScheduleOpen={setDeleteScheduleOpen}
        deleteBlackoutDetails={deleteBlackoutDetails}
        deleteBlackoutAction={props.deleteBlackoutAction}
      />
      <BlackoutContentPreview
        open={openPreview}
        setPreviewOpen={setPreviewOpen}
        previewDetails={previewDetails}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    projectDetails: state.ProjectReducer.projectDetails,
    blackoutsForAllChannel: state.BlackoutScheduleReducer.blackoutsForAllChannel,
    delete: state.BlackoutScheduleReducer.delete,
    accessLevel: state.loginReducer.authTokens?.accessLevel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllTheBlackoutsForAllChannelsAction: (p_id) =>
      dispatch(getAllTheBlackoutsForAllChannelsAction(p_id)),
    getAllLinearChannelAction: (p_id) => dispatch(getAllLinearChannelAction(p_id)),
    deleteBlackoutAction: (blackoutDetails, p_id) =>
      dispatch(deleteBlackoutAction(blackoutDetails, p_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlackoutSchedule);
