import React from "react";
import {
    Box,
    Container,
    Divider,
    Grid,
    TextField,
    Typography,
  } from "@material-ui/core";

const SignInErrorMsg = (props) => {
  return <div><Typography variant="h7" color="error">{props.errmsg}</Typography></div>;
};

export default SignInErrorMsg;
