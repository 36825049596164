import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Input,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { Image as ImageIcon } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Style from "assets/jss/material-dashboard-react/views/screen_widgetStyle";
import CustomDialog from "components/Dialog/Dialog";
import Expand_More from "assets/img/icons/svg/Ic_expand_more.svg";
import Snackbar from "components/Snackbar/CustomSnackbar";
import LoadingBar from "react-top-loading-bar";

import AddIcon from "assets/img/icons/svg/Ic_add.svg";
import deleteBucket from "assets/img/platformScreenVisualization/deleteBucket.svg";
import publish from "assets/img/platformScreenVisualization/publish.svg";
import Live_icon from "assets/img/icons/Group_live_icon.svg";
import Draft_icon from "assets/img/icons/Group_draft_icon.svg";

import { connect } from "react-redux";
import axios from "axios";

//Actions
import { publishSplashAction, publishIntroAction } from "../../actions/PlatformAction";

//DnD Component
import SortableIntroList from "components/Sortable/SortableIntroList";
import SortableIntroItem from "components/Sortable/SortableIntroItem";
import { getMediaType } from "components/PlatformScreensView/AppScreenView";

const useStyle = makeStyles(Style);

const largePlatforms = ["WEB_DES","AND_TV","LG_SMART_TV","SAMSUNG_SMART_TV","VIDAA_TV","APPLE_TV","DONGLE","FIRESTICK","STB","SKYGLASS_TV"]

const SystemScreens = (props) => {
  const classes = useStyle();

  const history = useHistory();

  //imgLoad state
  const [load, setLoad] = useState(false);
  const [splashVideoload, setSplashVideoload] = useState(false);
  const [introVideoload, setIntroVideoload] = useState(false);

  //Tabs state
  const [value, setValue] = useState("0");

  //Dialog state
  const [openPublish, setOpenPublish] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSplashDelete, setOpenSplashDelete] = useState(false);


  //Loadingbar state
  const [progress, setProgress] = useState(0);
  const [newS3, setNewS3] = useState(false);

  //Collapsible state
  const [expanded, setExpanded] = useState(-1);

  //Snackbar state
  const [snackBarOpen, setsnackBarOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");

  //button state
  const [disabledSplash, setDisabledSplash] = useState(true);
  const [disabledIntro, setDisabledIntro] = useState(true);

  //Delete image state
  const [deleteImage, setDeleteImage] = useState("");
  const [deleteSplashImage, setDeleteSplashImage] = useState("");

  //others
  const aspectRatioInfo = largePlatforms.includes(props.platform)?"**only 16x9 ratios are supported":"**only 9x16 ratios are supported"
  console.log("SystemScreen props", props);

  const handleClick = (x, i) => {
    setExpanded(expanded === i ? -1 : i);
    props.activeTab(i);
  };

  const getRatio = async (url) => {
    console.log("URL rat", url);
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        let ratio = img.width / img.height;
        resolve(ratio);
      };
    });
  };

  const getBase64Image = async (files) => {
    if (files.length === 1) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = async (e) => {
          const ratio = await getRatio(e.target.result);
          resolve([{ b64: reader.result, ratio }]);
        };
      });
    } else {
      const fileData = Object.values(files);
      const filePathsPromises = [];
      fileData.forEach((file) => {
        filePathsPromises.push(
          new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (e) => {
              const ratio = await getRatio(e.target.result);
              resolve({ b64: reader.result, ratio });
            };
          })
        );
      });
      const filePaths = await Promise.all(filePathsPromises);
      return filePaths;
    }
  };

  const getVideoDuration = (videoBase64) => {
    return new Promise((resolve) => {
      let video = document.createElement('video');
      video.src = videoBase64;
      video.onloadedmetadata = () => {
        resolve(video.duration);
      };
    });
  };

  const getVideoRatio = async (videoBase64) => {
    return new Promise((resolve) => {
      let video = document.createElement('video');
      video.src = videoBase64;
      video.onloadedmetadata = () => {
        console.log("width------>", video.videoWidth)
        console.log("height----->", video.videoHeight)

        const ratio = video.videoWidth / video.videoHeight;
        resolve(ratio);
      };
    });
  };
  const getBase64Vedio = async (files,durationLimit) => {
    if (files.length === 1) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = async (e) => {
          const duration = await getVideoDuration(e.target.result);
          // limit
          if(true){
            resolve([{ b64: reader.result, duration }]);
          }
        };
      });
    }
  };

  console.log(props.splashScreen,"props.splashScreen")


  const imageHandler = async (event) => {
      //Upload API
      const uploadAPI = async (images, files) => {
        const params = {
          platform: props.platform,
          p_id: props.p_id,
        };

        const headers = {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          Authorization: localStorage.getItem("token"),
        };

        return await axios
          .post(
            `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/intro-screen-upload`,
            {
              file:
                images.length > 1
                  ? [...Array(images.length)].map((_, index) => {
                      return images[index].b64;
                    })
                  : [images[0].b64],
            },
            { headers, params }
          )
          .then((res) => {
            return res;
          })
          .catch((err) => {
            console.log("Error:", err);
          });
      };

      //Check Aspect Ratio
      const checkAR = (images, platform) => {
        if (images.length > 1) {
          if (platform === "wide") {
            return images.every((img) => img.ratio > 1 && img.ratio < 2) ? true : false;
          } else if (platform === "small") {
            return images.every((img) => img.ratio <= 1) ? true : false;
          }
        } else if (images.length === 1) {
          if (platform === "wide") {
            return images[0].ratio > 1 && images[0].ratio < 2 ? true : false;
          } else if (platform === "small") {
            return images[0].ratio <= 1 ? true : false;
          }
        }
      };

      const checkVAR = (videos, platform) => {
        if (videos.length > 1) {
          if (platform === "wide") {
            return videos.every((vid) => vid.ratio > 1.76 && vid.ratio < 1.78) ? true : false;
          } else if (platform === "small") {
            return videos.every((vid) => (vid.ratio > 0.55 && vid.ratio < 0.57) || vid.ratio === 1) ? true : false;
          }
        } else if (videos.length === 1) {
          if (platform === "wide") {
            return videos[0].ratio > 1.76 && videos[0].ratio < 1.78 ? true : false;
          } else if (platform === "small") {
            return (videos[0].ratio > 0.55 && videos[0].ratio < 0.57) || videos[0].ratio === 1 ? true : false;
          }
        }
      };
    console.log(event)
    const _introscreensMetaData = {
      name:"Intro Screens",
      sizeLimit:1048576*5,
      // supportFiles : you need to change at accept prop if you are changing here
      supportFiles:["image/png","image/gif","video/mp4"],
      durationLimit:10
      }

    const _splashscreensMetaData = {
        name:"Splash Screen",
        // supportFiles : you need to change at accept prop if you are changing here
        sizeLimit:1048576*5,
        supportFiles:["image/png","video/mp4","image/gif"],
        durationLimit:10
      }

    const _currPlatform = props?.platform

    const _metadata = (event.target.id === _introscreensMetaData.name ||event.target.id=== "AddMore")?_introscreensMetaData:event.target.id===_splashscreensMetaData.name?_splashscreensMetaData:null
    console.log("🚀 ~ imageHandler ~ _metadata:", _metadata,event.target.id)

    if(!_metadata) return ;

    const files = event.target.files;

    const fileSizes = [...Array(files.length)].map((_, index) => files[index].size);

    const fileTypes = [...Array(files.length)].map((_, index) => {
      return files[index].type;
      });
      if(!(files?.length>0)){
        return [
          setSeverity("error"),
          setsnackBarOpen(true),
          setMessage("Image could not be uploaded. Please try again."),

        ]

      }

    if (fileSizes.every((size) => size > _metadata.sizeLimit)) {

      setSeverity("error");
      setsnackBarOpen(true);
    // NOTE: show real size limit
      setMessage(`Maximum file limit is ${_metadata.sizeLimit/1048576}MB.`);
      return ;
    }


    if(_metadata.name===_introscreensMetaData.name){
      let content ;
      let groupUpdate;
      let includesVideo = false
      let includesImage = false
      let S3image;
      if (
        fileTypes.every((type) => {
          if(type.toLowerCase().includes("image")){
            includesImage = true
          }else if (type.toLowerCase().includes("video")){
            includesVideo = true
          }
          return !_metadata.supportFiles.includes(type)
        })
      ) {
        return files.length
          ? [setSeverity("error"), setsnackBarOpen(true), setMessage("Only png,mp4 files are supported.")]
          : null;
      }
      if(includesVideo && includesImage  ){
        return files.length
          ? [setSeverity("error"), setsnackBarOpen(true), setMessage("You can only upload image or video")]
          : null;
    }
    console.log(props?.introScreens,"props?.introScreens","props?.introScreens")
    if(includesVideo &&props?.introScreens?.length>0 && (props?.introScreens?.every(url=>{
      return (getMediaType(url)==="image")
    })) ){
      return files.length
        ? [setSeverity("error"), setsnackBarOpen(true), setMessage("Images are already present in the screens")]
        : null;
  }
     if(includesImage){
        content = await  getBase64Image(files);
        console.log(props?.introScreens?.length,content.length)
        if(content.length>5 || 5<Math.abs((props?.introScreens?.length ||0)+(content.length))){
          return [
            setSeverity("warning"),
            setsnackBarOpen(true),
            setMessage("You can upload maximum of 5 Images"),
          ]
        }
        if(!(content.every((img) => img.ratio === content[0].ratio))){
          return [
            setSeverity("warning"),
            setsnackBarOpen(true),
            setMessage("Please ensure that all uploaded images have the same aspect ratio."),
          ]
         }
         if (largePlatforms.includes(_currPlatform)){
          if( checkAR(content, "wide")){
            setNewS3(true)
            S3image = await uploadAPI(content, files)
            setNewS3(false)
            if( S3image?.data?.uploadResult?.[0]?.Location){
              groupUpdate= [
                setNewS3(false),
                props.introScreens
                  ? props.setIntroScreens(
                      [...props.introScreens].concat(
                        [...Array(files.length)].map((_, index) => {
                          return S3image?.data?.uploadResult?.[index]?.Location;
                        })
                      )
                    )
                  : props.setIntroScreens(
                      [...Array(files.length)].map((_, index) => {
                        return S3image?.data?.uploadResult?.[index]?.Location;
                      })
                    ),
                setDisabledIntro(false),
              ]
            }else{
              return [
                setSeverity("warning"),
                setsnackBarOpen(true),
                setMessage("Something went Wrong Please Try again"),
              ]
            }
      
            console.log(S3image,"S3Image")
          }else{
            return [
              setSeverity("warning"),
              setsnackBarOpen(true),
              setMessage("This platform supports 16x9 image ratio only."),
            ]
          }
        }else{
          if(checkAR(content,"small")){
            setNewS3(true)
            S3image = await uploadAPI(content, files)
            setNewS3(false)
            if( S3image?.data?.uploadResult?.[0]?.Location){
            groupUpdate = [
                          props.introScreens
                            ? props.setIntroScreens(
                                [...props.introScreens].concat(
                                  [...Array(files.length)].map((_, index) => {
                                    return S3image?.data?.uploadResult?.[index]?.Location;
                                  })
                                )
                              )
                            : props.setIntroScreens(
                                [...Array(files.length)].map((_, index) => {
                                  return S3image?.data?.uploadResult?.[index]?.Location;
                                })
                              ),
                          setDisabledIntro(false),
                        ]
              }
              else{
                return [
                  setSeverity("warning"),
                  setsnackBarOpen(true),
                  setMessage("Something went Wrong Please Try again"),
                ]
              }
          }else{
            return[
              setSeverity("warning"),
              setsnackBarOpen(true),
              setMessage("This platform supports 1x1 and 9x16 image ratio only."),
            ]
          }
        }
     }
   
     if (includesVideo) {
      content = await getBase64Vedio(files, _metadata.durationLimit);
      const videoRatio = await getVideoRatio(content[0].b64);
      console.log("video ratio is ----->", videoRatio);
    
      const videoData = [{ ratio: videoRatio }];
    
      if (largePlatforms.includes(_currPlatform)) {
        if (checkVAR(videoData, "wide")) {
          (S3image = await uploadAPI(content, files))
         if( S3image?.data?.uploadResult?.[0]?.Location){
          groupUpdate = [
            props.setIntroScreens(
              [...Array(files.length)].map((_, index) => {
                return S3image?.data?.uploadResult?.[index]?.Location;
              })
            )
          ];
         }else{
          return [
            setSeverity("warning"),
            setsnackBarOpen(true),
            setMessage("Something went Wrong Please Try again"),
          ]
         }

        } else {
          return [
            setSeverity("warning"),
            setsnackBarOpen(true),
            setMessage("This platform supports 16x9 video ratio only."),
          ];
        }
      } else {
        if (checkVAR(videoData, "small")) {

          (S3image = await uploadAPI(content, files))
          if( S3image?.data?.uploadResult?.[0]?.Location){
            groupUpdate = [
              props.setIntroScreens(
                [...Array(files.length)].map((_, index) => {
                  return S3image?.data?.uploadResult?.[index]?.Location;
                })
              )
            ];
          }else{
            return [
              setSeverity("warning"),
              setsnackBarOpen(true),
              setMessage("Something went Wrong Please Try again"),
            ]
          }

        } else {
          return [
            setSeverity("warning"),
            setsnackBarOpen(true),
            setMessage("This platform supports 1x1 and 9x16 video ratios only."),
          ];
        }
      }
    }
    



    }else if (_metadata.name===_splashscreensMetaData.name){
      if (
        fileTypes.every((type) => {
          return !_metadata.supportFiles.includes(type)
        })
      ) {
        return files.length
          ? [setSeverity("error"), setsnackBarOpen(true), setMessage("Only png, mp4 files are supported.")]
          : null;
      }

        let content ;
        let groupUpdate;
     
        if (fileTypes[0].includes("video")) {
          content = await getBase64Vedio(files, _metadata.durationLimit);
          const videoRatio = await getVideoRatio(content[0].b64);
          console.log("video ratio is ----->", videoRatio);
      
          content[0].ratio = videoRatio;
      
          if (largePlatforms.includes(_currPlatform)) {
              if (checkVAR([content[0]], "wide")) {
                  groupUpdate = [
                      props.setSplashScreen(content[0].b64),
                      setDisabledSplash(false),
                      props.setSplashIcon(<img src={Draft_icon} style={{ width: "10px" }} />),
                  ];
              } else {
                  return [
                      setSeverity("warning"),
                      setsnackBarOpen(true),
                      setMessage("This platform supports 16x9 video ratio only."),
                  ];
              }
          } else {
              if (checkVAR([content[0]], "small")) {
                  groupUpdate = [
                      props.setSplashScreen(content[0].b64),
                      setDisabledSplash(false),
                      props.setSplashIcon(<img src={Draft_icon} style={{ width: "10px" }} />),
                  ];
              } else {
                  return [
                      setSeverity("warning"),
                      setsnackBarOpen(true),
                      setMessage("This platform supports 1x1 and 9x16 video ratios only."),
                  ];
              }
          }
      }
      
        else if (fileTypes[0].includes("image")){
           content = await  getBase64Image(files);

           if (largePlatforms.includes(_currPlatform)){
            if(checkAR(content, "wide")){
              groupUpdate = [
                props.setSplashScreen(content[0].b64),
                setDisabledSplash(false),
                props.setSplashIcon(<img src={Draft_icon} style={{ width: "10px" }} />),
              ]
            }else{
              return [
                setSeverity("warning"),
                setsnackBarOpen(true),
                setMessage("This platform supports 16x9 image ratio only."),
              ]
            }

          }else{
            if(checkAR(content, "small")){
                groupUpdate = [
                            props.setSplashScreen(content[0].b64),
                            setDisabledSplash(false),
                            props.setSplashIcon(<img src={Draft_icon} style={{ width: "10px" }} />),
                          ]

            }else{
              return [
                setSeverity("warning"),
                setsnackBarOpen(true),
                setMessage("This platform supports 1x1 and 9x16 image ratio only."),
              ]
            }
          }

        }

    }

  };

  const handleTabs = (event, newValue) => setValue(newValue);

  const publishScreenDialog = () => setOpenPublish(true);

  const deleteImg = (deletedImg) => {
    console.log(deletedImg,"deletedImg")
    const updatedScreens = props.introScreens.filter((img) => img !== deletedImg);
    props.setIntroScreens(updatedScreens);
   /* props.publishIntroAction({
      p_id: props.p_id,
      platform: props.platform,
      screenURL: props.introScreens,
    }), */
    setOpenDelete(false);
    setDeleteSplashImage("");
  };

  const deleteSplashImg = (deletedImg) => {

    setOpenSplashDelete(false);
    setDeleteSplashImage("");
  };

  const publishScreen = (screen) => {
    screen === 0
      ? [
          setNewS3(true),
          props.publishSplashAction({
            p_id: props.p_id,
            platform: props.platform,
            screenURL: props.splashScreen,
          }),
          props.setSplashEvent(true),
          setNewS3(false),
          setDisabledSplash(true),
        ]
      : [
          setNewS3(true),
          props.publishIntroAction({
            p_id: props.p_id,
            platform: props.platform,
            screenURL: props.introScreens,
          }),
          props.setIntroEvent(true),
          setNewS3(false),
        ];
    setOpenPublish(false);
  };

  //Enable/Disable introscreens' publish button when introScreens array is altered
  useEffect(() => {

    props.introScreens?.length ===
      props.appConfig?.introScreens?.[props.platform]?.imageUrls?.length &&
    props.introScreens?.every((image, index) => {
      return image === props.appConfig?.introScreens?.[props.platform]?.imageUrls?.[index];
    })
      ? [
          setDisabledIntro(true),
          props.setIntroIcon(<img src={Live_icon} style={{ width: "10px" }} />),
        ]
      : [
          setDisabledIntro(false),
          props.setIntroIcon(<img src={Draft_icon} style={{ width: "10px" }} />),
        ];
  }, [props.introScreens]);

  //Enable/Disable introscreens' publish button when freshIntro array is altered
  useEffect(() => {
    props.introScreens?.every((image, index) => {
      return image === props.freshIntro?.data?.data?.[index];
    })
      ? [
          setDisabledIntro(true),
          props.setIntroIcon(<img src={Live_icon} style={{ width: "10px" }} />),
        ]
      : [
          setDisabledIntro(false),
          props.setIntroIcon(<img src={Draft_icon} style={{ width: "10px" }} />),
        ];
  }, [JSON.stringify(props.freshIntro)]);

  //ProgressBar state for image uplaoad
  useEffect(() => {
    newS3 ? setProgress(progress + 20) : setProgress(100);

    let timer = setTimeout(() => {
      setProgress(100);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [newS3]);

  //ProgressBar state for publish splash
  useEffect(() => {
    if (props.freshSplash) setProgress(100);

    let timer = setTimeout(() => {
      setProgress(100);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [props.freshSplash]);

  //ProgressBar state for publish intro
  useEffect(() => {
    if (props.freshIntro) setProgress(100);

    let timer = setTimeout(() => {
      setProgress(100);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [props.freshIntro]);
  console.log(props.introScreens,"introScreens are updated")
  return (
    <Box>
      <div>
        <LoadingBar
          color="#885CF7"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
          shadow={true}
          height={3}
        />
      </div>
      <Snackbar
        open={snackBarOpen}
        severity={severity}
        message={message}
        setOpen={() => setsnackBarOpen(false)}
      />
      <Box display="flex" flexDirection="column">
        <Paper className={classes.paper3} style={{ boxShadow: "none" }} elevation={0}>
          <Tabs
            value={value}
            indicatorColor="secondary"
            onChange={handleTabs}
            TabIndicatorProps={{
              style: { height: "4px", backgroundColor: "#0192D4" },
            }}
          >
            <Tab
              value="0"
              className={classes.unSelectedAppTab}
              classes={{ selected: classes.apptab }}
              label="Screens"
            />
          </Tabs>
          {props.screens.map((x, i) => {
            return (
              <Box key={i}>
                <Card className={classes.screenCard}>
                  <Box display="flex" flexDirection="row" m={2} justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      <Typography className={classes.screenName}>{x}</Typography>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => {
                          handleClick(x, i);
                        }}
                      >
                        <img src={Expand_More} />
                      </Button>
                    </Box>
                  </Box>
                  <Box>
                    <Collapse in={expanded === i} timeout="auto" unmountOnExit>
                      <CardContent
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Box className={i ? classes.introUpload : classes.imgUpload}>
                          <label htmlFor={x}>
                            <Input
                              style={{
                                display: "none",
                                width: "max-content",
                              }}
                              id={x}
                              name={x}
                              type="file"
                              onChange={(event)=>imageHandler(event)}
                              onClick={(event) => {
                                props.accessLevel !== "user"
                                  ? (event.target.value = null)
                                  : [
                                      event.preventDefault(),
                                      event.target.id === "Splash Screen"
                                        ? props.setAccessSnackMessage(
                                            "You do not have access to upload a splash screen."
                                          )
                                        : props.setAccessSnackMessage(
                                            "You do not have access to upload intro screens."
                                          ),
                                      props.setAccessSnack(true),
                                    ];
                              }}
                              required
                              inputProps={{
                                accept: [".png",".mp4",".gif"],
                                multiple: x === "Intro Screens" ? true : false,
                              }}
                            />
                            <Box display="flex" alignItems="center" justifyContent={i ? "" : "center"}>
                              {x === "Splash Screen" ? (
                                props?.splashScreen ? (
                                  <Box className={classes.imgUpload}>
                                    {
                                      getMediaType(props?.splashScreen)?.toLowerCase()==="video" &&
                                      <div style={{display:"flex" ,flexDirection:"column"}} key={"splashVideo"}>
                                      <video  style={{
                                        width:"full",
                                        maxHeight: "200px",
                                        borderRadius: "4px",
                                      }} src={props.splashScreen}
                                      onLoad={() => setLoad(true)}
                                       />
                                         {/* {
                                        load ? (

                                        <img
                                          src={deleteBucket}
                                          className={classes.SplashdeleteImg}
                                          onClick={(e) => {
                                           e.stopPropagation()
                                           setDeleteSplashImage(props.splashScreen);
                                            setOpenSplashDelete(true);
                                          }}
                                        />
                                      ) : null} */}
                                      </div>
                                    }
                                    {
                                      getMediaType(props?.splashScreen)?.toLowerCase()==="image" &&
                                      <div style={{display:"flex" ,flexDirection:"column"}}  key={"splashImage"}>
                                         <img
                                      src={props.splashScreen}
                                      onError={props.onImgError}
                                      style={{
                                        maxWidth: "180px",
                                        maxHeight: "200px",
                                        borderRadius: "4px",
                                        cursor:"pointer"
                                      }}
                                      onLoad={() => setLoad(true)}
                                      className={classes.splashImage}
                                    />

                                    {/* {
                                        load ? (

                                        <img
                                          src={deleteBucket}
                                          className={classes.SplashdeleteImg}
                                          onClick={(e) => {
                                           e.stopPropagation()
                                            setDeleteSplashImage(props.splashScreen);
                                            setOpenSplashDelete(true);
                                          }}
                                        />
                                      ) : null} */}
                                      <div>
                                      <Box className={classes.uploadText}>
                                      <Typography
                                        className={classes.screenName}
                                        style={{
                                          width: "max-content",
                                          color: "#FFFFFF",
                                          backgroundColor:"black"
                                        }}
                                      >
                                        Upload Image/Video

                                      </Typography>

                                    </Box>
                                      <Typography
                                        className={classes.splashInfotext}

                                      >
                                       {aspectRatioInfo}

                                      </Typography>
                                      </div>

                                      </div>

                                    }

                                  </Box>
                                ) : (
                                  <Box>
                                      <Box
                                    style={{
                                      display: "flex",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Typography
                                    // className={classes.screenName}
                                     style={{
                                          width: "max-content",
                                          color: "#FFFFFF",
                                          backgroundColor:"black"
                                        }}  >
                                    Upload Image/Video
                                    </Typography>
                                   
                                    <ImageIcon className={classes.screenIcon} />
                                  </Box>
                                     <Typography

                                      style={{
                                        width: "max-content",
                                        color:"red",
                                        fontSize:"10px",
                                        marginTop:"5px"
                                      }}

                                          >
                                          {aspectRatioInfo}

    </Typography>
                                    
                                  </Box>
                                )
                              ) : props?.introScreens?.length ? null : (
                                <div>
                                    <Box className={classes.introUploadContainer}>
                                  <Typography className={classes.screenName} >
                                  Upload Image/Video
                                  </Typography>

                                  {/* <ImageIcon className={classes.screenIcon} /> */}
                                </Box>
                                  <Typography

                                  style={{
                                    width: "max-content",
                                    color:"red",
                                    fontSize:"10px",
                                    marginTop:"5px"
                                  }}

                                      >
                                       {aspectRatioInfo}

                                      </Typography>
                                </div>

                              )}
                            </Box>
                          </label>
                          {i ? (
                            props?.introScreens?.length >1 ? (
                              <>
                                <Box className={classes.introContainer}>
                                  <SortableIntroList
                                    p_id={props.p_id}
                                    platform={props.platform}
                                    items={props.introScreens}
                                    onChange={props.setIntroScreens}
                                    accessLevel={props.accessLevel}
                                    setAccessSnack={props.setAccessSnack}
                                    setAccessSnackMessage={props.setAccessSnackMessage}
                                    setAccessSnackSeverity={props.setAccessSnackSeverity}
                                    renderItem={(item, index, removeItem, items) => {
                                      return (
                                        <SortableIntroItem

                                          key={item}
                                          id={item}
                                          removeItem={removeItem}
                                          accessLevel={props.accessLevel}
                                          setAccessSnack={props.setAccessSnack}
                                          setAccessSnackMessage={props.setAccessSnackMessage}
                                          setAccessSnackSeverity={props.setAccessSnackSeverity}

                                        >
                                          <Box className={classes.imgContainer}>
                                            <Box>
                                              <img
                                                src={item}
                                                className={classes.introImg}
                                                title="Drag To Reorder"
                                                onLoad={() => requestAnimationFrame(() => setLoad(true))}
                                              />
                                            </Box>
                                          </Box>
                                        </SortableIntroItem>
                                      );
                                    }}
                                  />
                                </Box>
                              </>
                            ) : props?.introScreens?.length === 1 ? (
                             [ getMediaType(props?.introScreens[0])?.toLowerCase()==="image" &&
                              (<Box key={"introscreenImage"} className={classes.introUploadSingle}>
                              <img
                                src={props.introScreens}
                                style={{
                                  maxWidth: "180px",
                                  maxHeight: "200px",
                                  borderRadius: "4px",
                                }}
                                onLoad={() => setLoad(true)}
                              />
                              {load ? (
                                <>
                                       <img
                                  src={deleteBucket}
                                  className={classes.deleteImg}
                                  style={{marginTop:"6px"}}
                                  onClick={() => {
                                    setDeleteImage(props.introScreens[0]);
                                    setOpenDelete(true);
                                  }}
                                />
                                 <Typography


               style={{
                width: "max-content",
                color:"red",
                fontSize:"10px",
                marginTop:"5px"
              }}

                                      >
                                       {aspectRatioInfo}

                                      </Typography>


                                </>

                              ) : null}
                            </Box>),
                              getMediaType(props?.introScreens[0])?.toLowerCase()==="video"  &&
                              <Box key={"introscreenVideo"} style={{display:"flex",flexDirection:"column",padding:"2px"}}>
                                 <video    style={{
                                  maxWidth: "180px",
                                  maxHeight: "200px",
                                  borderRadius: "4px",
                                }} src={props?.introScreens[0]}
                                onLoadedData={() => {
                                    setIntroVideoload(true)
                                  }}
                                       />
                                       {introVideoload ? (
                                        <>
                                        <img
                                  src={deleteBucket}
                                  className={classes.SplashdeleteImg}
                                  style={{marginTop:"6px"}}
                                  onClick={() => {

                                    setDeleteImage(props.introScreens[0]);
                                    setOpenDelete(true);
                                  }}
                                />
                                       <Typography

                                  style={{
                                    width: "max-content",
                                    color:"red",
                                    fontSize:"10px",
                                    marginTop:"5px"
                                  }}

                                      >
                                       {aspectRatioInfo}

                                      </Typography>
                                        </>

                              ) : null}
                              </Box>

                              ]




                            ) : null
                          ) : null}
                        </Box>
                        {props?.introScreens?.length && i && getMediaType(props?.introScreens[0])?.toLowerCase()!=="video"? (
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "10px",
                            }}
                          >
                            <label
                              htmlFor="AddMore"

                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <Input
                                style={{
                                  display: "none",
                                  width: "max-content",
                                }}

                                id="AddMore"
                                name="AddMore"
                                type="file"
                                multiple
                                onChange={(event)=>imageHandler(event)}
                                onClick={(event) => {
                                  props.accessLevel !== "user"
                                    ? (event.target.value = null)
                                    : [
                                        event.preventDefault(),
                                        props.setAccessSnackMessage(
                                          "You do not have access to add more intro screens."
                                        ),
                                        props.setAccessSnack(true),
                                      ];
                                }}
                                required
                                inputProps={{
                                  accept: [".png",".mp4",".gif"],
                                  multiple: true,
                                }}
                              />
                              {load ? (
                                <>
                                  <img src={AddIcon} />
                                  <Typography className={classes.addnew}>Add More</Typography>
                                </>
                              ) : null}
                            </label>
                          </Box>
                        ) : null}
                      </CardContent>
                    </Collapse>
                  </Box>
                </Card>
              </Box>
            );
          })}
        </Paper>
      </Box>
      <Box paddingTop="24px">
        <Paper className={classes.paper4}>
          <Box padding="24px 24px 0px 24px">
            <Button
              variant="contained"
              className={
                props.activeScreen
                  ? disabledIntro
                    ? classes.saveButtonDisabled
                    : classes.saveButton
                  : disabledSplash
                  ? classes.saveButtonDisabled
                  : classes.saveButton
              }
              style={{ width: "100%" }}
              onClick={
                props.accessLevel !== "user"
                  ? publishScreenDialog
                  : () => {
                      props.setAccessSnackMessage(
                        `You do not have access to publish ${
                          props.activeScreen ? "intro screens" : "the splash screen"
                        }.`
                      );
                      props.setAccessSnack(true);
                    }
              }
              disabled={props.activeScreen ? disabledIntro : disabledSplash}
            >
              <Typography className={classes.ButtonText}>
                Publish {props.screens[props.activeScreen]}
              </Typography>
            </Button>
          </Box>
          <Box padding="20px" display="flex" justifyContent="center">
            <Button
              onClick={() => history.goBack()}
              style={{
                width: "100%",
                height: "48px",
                borderRadius: "24px",
              }}
            >
              <Typography className={classes.draftTypo}>Cancel</Typography>
            </Button>
          </Box>
        </Paper>
      </Box>

      {/*Dialog for Publish Screen */}

      <CustomDialog
        open={openPublish}
        title={
          <span className={classes.dialogDesc}>
            Are you sure you want to <br /> publish{" "}
            <span className={classes.dialogDescFocus}>{props.screens[props.activeScreen]}</span>?
          </span>
        }
        button1="Cancel"
        button2="Yes"
        button1Event={() => setOpenPublish(false)}
        button2Event={() => publishScreen(props.activeScreen)}
        icon={publish}
      />

      <CustomDialog
        open={openDelete}
        title={
          <span className={classes.dialogDesc}>
            Are you sure you want to <br /> delete this Intro  {getMediaType(deleteImage || "")?.toLowerCase()}?
          </span>
        }
        button1="Cancel"
        button2="Yes"
        button1Event={() => setOpenDelete(false)}
        button2Event={() => deleteImg(deleteImage)}
        icon={deleteBucket}
        margin="8px"
        width="24px"
      />

<CustomDialog
        open={openSplashDelete}
        title={
          <span className={classes.dialogDesc}>
            Are you sure you want to <br /> delete this Splash {getMediaType(props?.splashScreen)?.toLowerCase()}?
          </span>
        }
        button1="Cancel"
        button2="Yes"
        button1Event={() => setOpenSplashDelete(false)}
        button2Event={() => deleteImg(deleteImage)}
        icon={deleteBucket}
        margin="8px"
        width="24px"
      />
    </Box>
  );
};

const mapStateToProps = (State) => {
  return {
    freshSplash: State.screenVisualizationReducer.splashResponse,
    freshIntro: State.screenVisualizationReducer.introResponse,
    appConfig: State.ProjectReducer.projectDetails.appConfig,
    accessLevel: State.loginReducer.authTokens?.accessLevel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    publishSplashAction: (screenDetails) => {
      dispatch(publishSplashAction(screenDetails));
    },
    publishIntroAction: (screenDetails) => {
      dispatch(publishIntroAction(screenDetails));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemScreens);
