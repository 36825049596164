import React from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

const data = [
  { "timeline": "2024-08-01", "total_devices": 1000 },
  { "timeline": "2024-08-02", "total_devices": 1200 },
  { "timeline": "2024-08-03", "total_devices": 1400 },
  { "timeline": "2024-08-04", "total_devices": 1300 },
  { "timeline": "2024-08-05", "total_devices": 1700 },
  { "timeline": "2024-08-06", "total_devices": 1500 },
  { "timeline": "2024-08-07", "total_devices": 1200 },
  { "timeline": "2024-08-08", "total_devices": 1600 },
  { "timeline": "2024-08-09", "total_devices": 1900 },
  { "timeline": "2024-08-10", "total_devices": 2000 },
  { "timeline": "2024-08-11", "total_devices": 1800 },
  { "timeline": "2024-08-12", "total_devices": 2200 },
  { "timeline": "2024-08-13", "total_devices": 2100 },
  { "timeline": "2024-08-14", "total_devices": 2300 },
  { "timeline": "2024-08-15", "total_devices": 1900 },
  { "timeline": "2024-08-16", "total_devices": 2400 },
  { "timeline": "2024-08-17", "total_devices": 2600 },
  { "timeline": "2024-08-18", "total_devices": 2200 },
  { "timeline": "2024-08-19", "total_devices": 2800 },
  { "timeline": "2024-08-20", "total_devices": 3000 },
  { "timeline": "2024-08-21", "total_devices": 2900 },
  { "timeline": "2024-08-22", "total_devices": 3100 },
  { "timeline": "2024-08-23", "total_devices": 3200 },
  { "timeline": "2024-08-24", "total_devices": 3300 },
  { "timeline": "2024-08-25", "total_devices": 3500 },
  { "timeline": "2024-08-26", "total_devices": 3400 },
  { "timeline": "2024-08-27", "total_devices": 3700 },
  { "timeline": "2024-08-28", "total_devices": 3900 },
  { "timeline": "2024-08-29", "total_devices": 4000 },
  { "timeline": "2024-08-30", "total_devices": 3800 },
];

export default function TotalPlatformsLineChart() {
  return (
    <ResponsiveContainer width="100%">
      <LineChart data={data}>
        {/* Removed CartesianGrid to remove background lines */}
        <XAxis
          dataKey="timeline"
          tickFormatter={(tick, index) => {
            if (index % 5 === 0) {
              return tick;
            }
            return "";
          }}
        />
        <YAxis />
        <Tooltip
          labelFormatter={(label) => `Date: ${label}`}
          formatter={(value) => [`${value} devices`, "Total Devices"]}
        />
        <Line
          type="monotone"
          dataKey="total_devices"
          stroke="#8884d8" // Darker line color
          strokeWidth={2} // Thicker line
          dot={false} // Removes dots from the line
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
