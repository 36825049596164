import {
  Box,
  Card,
  Grid,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  MenuList,
  Typography,
  Button,
} from "@material-ui/core";
//import Card from "components/Card/Card";
import Menuitem from "components/Menu/MenuItem";
import React, { useState, useEffect } from "react";
import EditProjectDetails from "./EditProjectDetails";

import Style from "../../../../assets/jss/material-dashboard-react/views/EditProjectStyle";
import EditProjectSettings from "./EditProjectSettings";

import { updateProjectDetailsAction } from "screens/Projects/actions/projectActions";
import { getAllLanguage } from "../../actions/projectActions";
import { connect, useSelector } from "react-redux";

import { useHistory, Redirect } from "react-router-dom";
import EditProjectConfig from "./EditProjectConfig";
import FeatureConfig from "./FeatureConfig";

const useStyle = makeStyles(Style);

const EditProjects = (props) => {

  if(typeof(props?.location?.state?.data?._id)!=="string"){
    return <Redirect  to="/admin" />
  }

  const [Change, setChange] = useState(0);
  const [projectData, setProjectData] = useState(props.location.state.data);
  const [UpdateDetails, setUpdateDetails] = useState({});
  const [image16, setImage16] = useState(false);
  const [image1, setImage1] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  const [cmsConfig, setCmsConfig] = useState(props.Config?.data?.config);

  const classes = useStyle();

  useEffect(() => {
    props.getAllLanguage();
  }, []);

  useEffect(() => {
    setUpdateDetails({ ...UpdateDetails, p_id: projectData.p_id });
  }, []);

  useEffect(() => {
    setProjectData({ ...projectData, cmsConfig: cmsConfig });
  }, [cmsConfig]);

  useEffect(() => {
    setCmsConfig(props.Config?.data?.config);
  }, [props.Config?.data?.config]);

  const handleFeatureConfig = () => {
    setChange(3);
  };

  const handleConfig = () => {
    setChange(2);
  };

  const handleSettings = () => {
    setChange(1);
  };

  const handleProject = () => {
    setChange(0);
  };

  const handleUpdate = () => {
    const bgOption = projectData.config?.bgOption || "bgColor"
    if(
      (bgOption==="bgImage" && !projectData.config?.bgImage?.["1x1"] && !projectData.config?.bgImage?.["16x9"] ) ||
      (bgOption==="gdColor" && !projectData.config?.colors?.gdColor) ||
      (bgOption==="bgColor" && !projectData.config?.colors?.bgColor) ||
      (projectData?.aspectRatios?.length<=0) ||
      (projectData.config?.colors?.fgColor === undefined || projectData.config?.colors?.fgColor === "") ||
      (projectData.config?.font === undefined || projectData.config?.font === "") ||
      (props?.projectData?.tags?.length<=0)
      ){
      setErrorMessage("mandatory field *");
    }else{
      props.updateProjectDetailsAction(projectData);
      history.push({ pathname: "/admin/projects" });
    }

  };

  const handleCancel = () => {
    history.push({ pathname: "/admin/projects" });
  };

  return (
    <Box>
      <Box>
        <Typography className={classes.Heading}>Edit Project</Typography>
      </Box>
      <Box pt="24px" pb="75px">
        <Grid container>
          <Grid item sm={4} md={4} lg={3}>
            <Box>
              <Card className={classes.Card}>
                <Box paddingTop="24px" paddingLeft="16px">
                  <MenuList>
                    <MenuItem onClick={handleProject}>
                      <ListItemText className={Change === 0 ? classes.SelectedList : classes.List}>
                        Project Details
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleSettings}>
                      <ListItemText className={Change === 1 ? classes.SelectedList : classes.List}>
                        Project Settings
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleConfig}>
                      <ListItemText className={Change === 2 ? classes.SelectedList : classes.List}>
                        CMS Config
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleFeatureConfig}>
                      <ListItemText className={Change === 3 ? classes.SelectedList : classes.List}>
                        Feature Config
                      </ListItemText>
                    </MenuItem>
                  </MenuList>
                </Box>
              </Card>
            </Box>
          </Grid>
          <Grid item sm={8} md={8} lg={9}>
            {Change === 0 ? (
              <EditProjectDetails
                projectData={projectData}
                setProjectData={setProjectData}
                setImage16={setImage16}
                setImage1={setImage1}
                image1={image1}
                image16={image16}
              />
            ) : Change === 1 ? (
              <EditProjectSettings
                ErrorMessage={ErrorMessage}
                projectData={projectData}
                setProjectData={setProjectData}
                allLanguage={props.allLanguage}
              />
            ) : Change === 2 ? (
              <EditProjectConfig configDetails={projectData} setConfigDetails={setCmsConfig} cmsConfig={cmsConfig} />
              ) :Change === 3 ?  (
                
                <FeatureConfig featureConfig={props.featureConfig}  />
            ):(
              ""
            )
            }
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          width: "100%",
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100vw",
          boxSizing: "border-box",
        }}
      >
        <Card className={classes.bottomCard}>
          <Box justifyContent="flex-end" display="flex" flexDirection="row" alignItems="center">
            <Box paddingLeft="auto" paddingTop="1%" paddingBottom="2%">
              <Button variant="outlined" className={classes.cancelButton} onClick={handleCancel}>
                <Typography className={classes.cancelButtonText}>Cancel</Typography>
              </Button>
            </Box>
            <Box paddingLeft="2%" paddingTop="1%" paddingBottom="2%" paddingRight="5%">
              <Button className={classes.addButton} onClick={handleUpdate}>
                <Typography className={classes.addButtonText}>Update</Typography>
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

const mapStateToProps = (State) => {
  return {
    allLanguage: State.ProjectReducer.allLanguage,
    Config: State.ProjectReducer.kalturaConfig,
    featureConfig: State.ProjectReducer.featureConfig,

  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    updateProjectDetailsAction: (updateDetails) => {
      dispatch(updateProjectDetailsAction(updateDetails));
    },
    getAllLanguage: () => {
      dispatch(getAllLanguage());
    },
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(EditProjects);
